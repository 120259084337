<template>
  <section ref="anchor" class="partners">
    <div v-html-safe="content.title" class="font-title partners__title" />
    <div class="partners__logos">
      <div
        v-for="(partner, index) in content.partners"
        :key="index"
        class="partners__logos-item"
      >
        <div class="picture">
          <img
            v-if="partner.length"
            :src="`/img/partners/${partner}`"
            alt="logo"
          />
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { partnersBlock } from '@/content/data'
import offsetMixin from '@/mixins/offsetMixin'

export default {
  name: 'QueuePartners',
  mixins: [offsetMixin],
  data() {
    return {
      content: partnersBlock,
    }
  },
}
</script>

<style lang="sass" scoped>
.partners
  padding: 16rem $desktopHorizontalPaddings 0
  @media (max-width: 1279px)
    padding: $mobileVerticalPaddings $mobileHorizontalPaddings
  &__title
    @media (max-width: 415px)
      font-size: 2.7rem

  &__logos
    display: flex
    flex-wrap: wrap
    margin-top: 6rem
    @media (max-width: 1279px)
      justify-content: space-between
      flex-wrap: wrap
      margin-top: .4rem
    &-item
      width: 25%
      padding: 3.5rem 3.9rem
      margin-top: 2rem
      border-right: 1px solid $backgroundGray
      @media (min-width: 1440px) and (max-width: 1599px)
        padding: 1.5rem 2.5rem
      @media (min-width: 1280px) and (max-width: 1439px)
        padding: 0 2rem
      @media (max-width: 1279px)
        width: 15.8rem
        padding: 0
        margin-top: 2rem
      @media (min-width: 768px) and (max-width: 1024px)
        border: none
      @media (max-width: 768px)
        border-left: 1px solid $backgroundGray
        &:nth-of-type(1)
          border-right: none
        &:nth-of-type(3)
          border-right: none
        &:nth-of-type(5)
          border-right: none
        &:nth-of-type(7)
          border-right: none
      &:first-of-type
        @media (min-width: 1024px)
          border-left: 1px solid $backgroundGray
      &:nth-of-type(5)
        @media (min-width: 1280px)
          border-left: 1px solid $backgroundGray
      .picture
        height: 12.7rem
        border-radius: 18px
        @media (max-width: 1279px)
          height: 7.6rem
        img
          width: 100%
          height: 100%
          object-fit: contain
          object-position: center
</style>
