<template>
  <footer ref="anchor" class="footer">
    <div class="footer__content">
      <div class="font-title footer__content-title">{{ content.title }}</div>
      <div class="footer__content-info">
        <div class="contacts">
          <div class="contacts__data">
            <div
              v-for="(item, index) in content.contacts"
              :key="index"
              class="contacts__data-item"
            >
              <div v-html-safe="item.title" class="item__title" />
              <div v-html-safe="item.value" class="font-small item__value" />
            </div>
          </div>
          <div class="contacts__map">
            <img
              :src="`/img/${content.map}`"
              alt="map"
              class="contacts__map-image"
            />
          </div>
        </div>
        <div class="form">
          <lead-form v-on="$listeners" />
        </div>
      </div>
    </div>
    <div class="footer__copyright">{{ `${content.copyright} ${currentYear}` }}</div>
  </footer>
</template>

<script>
import { footerBlock } from '@/content/data'
import LeadForm from '@/components/common/Form/LeadForm'
import offsetMixin from '@/mixins/offsetMixin'
export default {
  name: 'FooterComponent',
  components: { LeadForm },
  mixins: [offsetMixin],
  data() {
    return {
      content: footerBlock,
      currentYear: new Date().getFullYear(),
    }
  },
}
</script>

<style lang="sass" scoped>
.footer
  background: $textPrimary
  &__content
    padding: 8rem $desktopHorizontalPaddings 0
    @media (max-width: 1279px)
      padding: 4rem $mobileHorizontalPaddings 0
    &-title
      margin-bottom: 4.5rem
      color: $textWhite
      @media (max-width: 1279px)
        margin-bottom: 2.4rem
    &-info
      display: flex
      justify-content: space-between
      @media (max-width: 1279px)
        flex-direction: column
        justify-content: flex-start
      .contacts
        width: 57.5%
        @media (max-width: 1279px)
          width: 100%
        &__data
          display: flex
          justify-content: space-between
          margin-bottom: 3.2rem
          @media (max-width: 1279px)
            flex-direction: column
            justify-content: flex-start
            margin-bottom: 2rem
          &-item
            width: 48.9%
            @media (max-width: 1279px)
              width: 100%
              margin-bottom: 2rem
            .item__title
              margin-bottom: 2.6rem
              font-family: $primaryFont
              font-weight: 400
              font-size: 1.8rem
              line-height: 2.4rem
              color: $textGray
              @media (max-width: 1279px)
                margin-bottom: .8rem
                font-size: 1.6rem
                line-height: 2rem
            .item__value
              color: $textLightWhite
              ::v-deep a
                color: $textLightWhite
                text-decoration: none
        &__map
          margin-bottom: 6rem
          @media (max-width: 1279px)
            margin-bottom: 4rem

          &-image
            width: 100%
            height: 17.6rem
            object-fit: cover
            object-position: center
            border-radius: 24px
      .form
        width: 32.2%
        @media (max-width: 1279px)
          width: 100%
  &__copyright
    display: flex
    justify-content: center
    align-items: center
    padding: 2.6rem
    font-family: $secondaryFont
    font-size: 1.2rem
    color: $textGray
    border-top: 1px solid $textSecondary

::v-deep .form-callback
  display: flex
  flex-direction: column
  &__input
    border: 1px solid $textSecondary
    margin-bottom: 2.4rem

  &__button
    width: 48%
    height: 4.4rem
    color: $buttonPrimary
    border: 1px solid $buttonPrimary
    transition: all .3s ease
    @media (max-width: 1279px)
      width: 55%
      margin-bottom: 4rem
    &:hover
      border: 1px solid $buttonHover
      color: $buttonHover
    &:active
      border: 1px solid $buttonClicked
      color: $buttonClicked

    &.disabled
      cursor: not-allowed
      color: $textSecondary
      border: 1px solid $textSecondary
      &:hover
        color: $textSecondary
        border: 1px solid $textSecondary
      &:active
        color: $textSecondary
        border: 1px solid $textSecondary

  &__checkbox
    margin-bottom: 2.6rem

::v-deep .input__content-prompt
  background: $textPrimary
  color: $textGray
::v-deep .input__content-input
    background: $textPrimary
    color: $textGray
</style>
