<template>
  <div>
    <fixed-menu-desktop @show-form="redirectToLegacyPage" />
    <float-menu-desktop @show-form="redirectToLegacyPage" />
    <fixed-menu-mobile @show-form="redirectToLegacyPage" />
    <float-menu-mobile @show-form="redirectToLegacyPage" />
    <header-component
      @show-form-presentation="showFormPresentation"
      @show-form-demonstration="showFormDemonstration"
    />
    <queue-decision-first />
    <queue-partners />
    <queue-decision />
    <queue-form @requested="showRequestModal" />
    <queue-description />
    <queue-indicators @show-form="showForm" />
    <queue-terms />
    <queue-case @show-form="showForm" />
    <footer-component @requested="showRequestModal" />
    <modal-request-result
      :visible="modalRequestOpened"
      :result="requestResult"
      @close="modalRequestOpened = false"
    />
    <modal-form
      :visible="modalFormOpened"
      @close="modalFormOpened = false"
      @requested="showRequestModal"
    />
    <modal-form-demonstration
      :is-demonstration="true"
      :visible="modalFormDemonstrationOpened"
      @close="modalFormDemonstrationOpened = false"
      @requested="showRequestDemonstrationModal"
    />
    <modal-form-presentation
      :is-presentation="true"
      :visible="modalFormPresentationOpened"
      @close="modalFormPresentationOpened = false"
      @requested="showRequestPresentationModal"
    />
  </div>
</template>

<script>
import FixedMenuDesktop from '@/components/menu/FixedMenuDesktop'
import FloatMenuDesktop from '@/components/menu/FloatMenuDesktop'
import FixedMenuMobile from '@/components/menu/FixedMenuMobile'
import FloatMenuMobile from '@/components/menu/FloatMenuMobile'
import FooterComponent from '@/components/Footer.vue'
import HeaderComponent from '@/components/Header.vue'
import QueueDecision from '@/components/QueueDecision'
import QueueDecisionFirst from '@/components/QueueDecisionFirst.vue'
import QueueDescription from '@/components/QueueDescription'
import QueueForm from '@/components/QueueForm'
import QueueIndicators from '@/components/QueueIndicators'
import QueueCase from '@/components/QueueCase'
import QueueTerms from '@/components/QueueTerms'
import QueuePartners from '@/components/QueuePartners'
import ModalRequestResult from '@/components/common/Modals/ModalRequestResult'
import ModalForm from '@/components/common/Modals/ModalForm'
import ModalFormPresentation from '@/components/common/Modals/ModalFormPresentation.vue'
import ModalFormDemonstration from '@/components/common/Modals/ModalFormDemonstration.vue'

export default {
  name: 'LandingPage',
  components: {
    FixedMenuDesktop,
    FloatMenuDesktop,
    QueueDecisionFirst,
    FixedMenuMobile,
    FloatMenuMobile,
    QueueDescription,
    HeaderComponent,
    QueueDecision,
    FooterComponent,
    QueueForm,
    QueueIndicators,
    QueueCase,
    QueueTerms,
    QueuePartners,
    ModalRequestResult,
    ModalForm,
    ModalFormPresentation,
    ModalFormDemonstration,
  },
  data() {
    return {
      modalRequestOpened: false,
      modalFormOpened: false,
      modalFormPresentationOpened: false,
      modalFormDemonstrationOpened: false,
      requestResult: '',
    }
  },
  methods: {
    // открытие модальной формы заявки
    showForm() {
      this.modalFormOpened = true
    },
    showFormPresentation() {
      this.modalFormPresentationOpened = true
    },
    showFormDemonstration() {
      this.modalFormDemonstrationOpened = true
    },
    // вывод сообщения о результате отправки
    showRequestModal(result) {
      this.modalFormOpened = false
      this.requestResult = result
      this.modalRequestOpened = true
    },

    showRequestPresentationModal(result) {
      this.modalFormPresentationOpened = false
      this.requestResult = result
      this.modalRequestOpened = true
    },

    showRequestDemonstrationModal(result) {
      this.modalFormDemonstrationOpened = false
      this.requestResult = result
      this.modalRequestOpened = true
    },

    redirectToLegacyPage() {
      const queryParams = window.location.search

      this.$router.push(`/legal/${queryParams}`)
    },
  },
}
</script>

<style lang="sass" scoped>
::v-deep .v-modal
  background: rgba(25, 31, 38, 0.7)
</style>
