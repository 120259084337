const mobileMenuHeight = 53
const desktopMenuHeight = 80

const getRemCoeff = () => {
  const width = window.innerWidth

  if (width < 375) {
    return 0.8
  }

  if (width > 374 && width < 768) {
    return (10 + (10 * (width - 375)) / 392) * 0.1
  }

  if (width > 767 && width < 1024) {
    return (10 + (3.3 * (width - 768)) / 255) * 0.1
  }

  if (width > 1023 && width < 1280) {
    return (8 + (2 * (width - 1024)) / 255) * 0.1
  }

  if (width > 1279 && width < 1440) {
    return (10 + (1.2 * (width - 1280)) / 159) * 0.1
  }

  if (width > 1439 && width < 1600) {
    return (10 + (width - 1440) / 159) * 0.1
  }

  if (width > 1599 && width < 1920) {
    return (10 + (width - 1600) / 240) * 0.1
  }

  if (width > 1919) {
    return (10 + (width - 1900) / 240) * 0.1
  }
}

export const menuHeight = () =>
  window.innerWidth < 1280
    ? mobileMenuHeight * getRemCoeff()
    : desktopMenuHeight * getRemCoeff()
