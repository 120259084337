import { topMenu } from '@/content/data'

export default {
  data() {
    return {
      menu: topMenu,
    }
  },
  methods: {
    goToSection(section) {
      window.scrollTo({
        top: this.$store.getters.getOffsets[section] + 1,
        behavior: 'smooth',
      })
    },
  },
}
