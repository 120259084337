import { mapGetters } from 'vuex'
export default {
  data() {
    return {
      currentScroll: 0,
      visibleElement: '',
    }
  },
  computed: {
    ...mapGetters({
      offsets: 'getOffsets',
    }),
    isVisible() {
      return this.$store.getters.getOffsets.QueueDecisionFirst < this.currentScroll
    },
  },
  mounted() {
    this.scrollHandler()
    document.addEventListener('scroll', this.scrollHandler)
  },
  beforeDestroy() {
    document.removeEventListener('scroll', this.scrollHandler)
  },
  methods: {
    // получение текущего скролла документа при прокрутке
    scrollHandler() {
      this.visibleElement = ''
      const menu = this.menu.menuItems

      this.currentScroll =
        window.pageYOffset || document.documentElement.scrollTop
      for (let i = 0; i < menu.length - 1; i++) {
        if (
          this.currentScroll > this.offsets[menu[i].component] &&
          this.currentScroll < this.offsets[menu[i + 1].component]
        ) {
          this.visibleElement = menu[i].component
          break
        }
      }

      if (!this.visibleElement.length) {
        this.visibleElement = 'FooterComponent'
      }
      // костыль для перехода активного пункта меню на Контакты (футер не скроллится до верха страницы)
      if (
        this.visibleElement === 'QueuePartners' &&
        this.currentScroll - this.offsets.QueuePartners > 500
      ) {
        this.visibleElement = 'FooterComponent'
      }
    },
  },
}
