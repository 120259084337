<template>
  <div>
    <div v-if="isLoading" class="modal">
      <div class="loader" />
    </div>

    <form
      v-if="!isDemonstration && !isPresentation"
      name="contacts"
      class="form-callback"
    >
      <double-input
        ref="nameInput"
        :title="content.placeholders.name"
        :has-error="hasNameError"
        :error-text="nameError"
        :class="[inputClass(hasNameError)]"
        @input-focus="hasNameError = false"
        @input-changed="getName"
      />
      <double-input
        ref="phoneInput"
        :title="content.placeholders.phone"
        mask="+7 (###) ###-##-##"
        :has-error="hasPhoneError"
        :error-text="phoneError"
        :class="[inputClass(hasPhoneError)]"
        @input-focus="hasPhoneError = false"
        @input-changed="getPhone"
      />
      <double-input
        ref="emailInput"
        :title="content.placeholders.email"
        :has-error="hasEmailError"
        :error-text="emailError"
        :class="[inputClass(hasEmailError)]"
        @input-focus="hasEmailError = false"
        @input-changed="getEmail"
      />
      <el-checkbox v-model="isAgree" class="form-callback__checkbox">
        {{ content.checkbox.text }}
        <a
          :href="content.checkbox.url"
          target="_blank"
          class="form-callback__checkbox_link"
        >
          {{ content.checkbox.link }}
        </a>
      </el-checkbox>
      <button
        :class="['form-callback__button', 'font-small', { disabled: !isAgree }]"
        @click.prevent="startRequest"
      >
        {{ content.button }}
      </button>
    </form>

    <form v-else-if="isDemonstration" name="contacts" class="form-callback">
      <double-input
        ref="nameInput"
        :title="content.placeholdersDemonstration.name"
        :has-error="hasNameError"
        :error-text="nameError"
        :class="[inputClass(hasNameError)]"
        @input-focus="hasNameError = false"
        @input-changed="getName"
      />
      <double-input
        ref="emailInput"
        :title="content.placeholdersDemonstration.email"
        :has-error="hasEmailError"
        :error-text="emailError"
        :class="[inputClass(hasEmailError)]"
        @input-focus="hasEmailError = false"
        @input-changed="getEmail"
      />
      <double-input
        ref="phoneInput"
        :title="content.placeholdersDemonstration.phone"
        mask="+7 (###) ###-##-##"
        :has-error="hasPhoneError"
        :error-text="phoneError"
        :class="[inputClass(hasPhoneError)]"
        @input-focus="hasPhoneError = false"
        @input-changed="getPhone"
      />
      <el-checkbox v-model="isAgree" class="form-callback__checkbox">
        {{ content.checkbox.text }}
        <a :href="content.checkbox.url" target="_blank">
          {{ content.checkbox.link }}
        </a>
      </el-checkbox>
      <button
        :class="['form-callback__button', 'font-small', { disabled: !isAgree }]"
        @click.prevent="startRequest"
      >
        {{ content.button }}
      </button>
    </form>

    <form v-else-if="isPresentation" name="contacts" class="form-callback">
      <double-input
        ref="nameInput"
        :title="content.placeholdersPresentation.name"
        :has-error="hasNameError"
        :error-text="nameError"
        :class="[inputClass(hasNameError)]"
        @input-focus="hasNameError = false"
        @input-changed="getName"
      />
      <double-input
        ref="emailInput"
        :title="content.placeholdersPresentation.email"
        :has-error="hasEmailError"
        :error-text="emailError"
        :class="[inputClass(hasEmailError)]"
        @input-focus="hasEmailError = false"
        @input-changed="getEmail"
      />
      <double-input
        ref="phoneInput"
        :title="content.placeholdersPresentation.phone"
        mask="+7 (###) ###-##-##"
        :class="[inputClass(hasPhoneError)]"
        @input-focus="hasPhoneError = false"
        @input-changed="getPhone"
      />
      <el-checkbox
        v-model="isAgree"
        class="form-callback__checkbox checkbox__first"
      >
        {{ content.checkbox.text }}
        <a :href="content.checkbox.url" target="_blank">
          {{ content.checkbox.link }}
        </a>
      </el-checkbox>
      <el-checkbox v-model="isAgreeCall" class="form-callback__checkbox">
        {{ content.checkbox.textPresentation }}
      </el-checkbox>
      <button
        :class="['form-callback__button', 'font-small', { disabled: !isAgree }]"
        @click.prevent="startRequest"
      >
        {{ content.button }}
      </button>
    </form>
  </div>
</template>

<script>
import axios from 'axios'
import { formData } from '@/content/data'
import DoubleInput from '@/components/common/Form/DoubleInput'

// eslint-disable-next-line
const EMAIL_VALIDATION_REGEXP = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/

export default {
  name: 'LeadForm',
  components: { DoubleInput },
  props: {
    isDemonstration: {
      type: Boolean,
      default: false,
    },
    isPresentation: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      content: formData,
      name: '',
      hasNameError: false,
      nameError: '',
      phone: '',
      hasPhoneError: false,
      phoneError: '',
      email: '',
      hasEmailError: false,
      emailError: '',
      isAgree: true,
      isAgreeCall: false,
      isLoading: false,
    }
  },
  computed: {
    hasValidationErrors() {
      return this.hasNameError || this.hasPhoneError || this.hasEmailError
    },
    hasValidationPresentationErrors() {
      return this.hasNameError || this.hasEmailError
    },
  },
  methods: {
    getName(name) {
      this.name = name
    },
    getPhone(phone) {
      this.phone = phone
    },
    getEmail(email) {
      this.email = email
    },
    inputClass(hasError) {
      return hasError ? 'form-callback__error' : 'form-callback__input'
    },
    validateData() {
      if (!this.name.length) {
        this.hasNameError = true
        this.nameError = 'Поле обязательно для заполнения'
      }
      if (this.phone.length !== 18) {
        this.hasPhoneError = true
        this.phoneError = this.phone.length
          ? 'Неверный формат номера'
          : 'Поле обязательно для заполнения'
      }
      if (!EMAIL_VALIDATION_REGEXP.test(this.email)) {
        this.hasEmailError = true
        this.emailError = this.email.length
          ? 'Указанная почта не существует '
          : 'Поле обязательно для заполнения'
      }
    },

    getUtmParams() {
      const urlParams = new URLSearchParams(window.location.search)

      return {
        utm_source: urlParams.get('utm_source') || '',
        utm_medium: urlParams.get('utm_medium') || '',
        utm_campaign: urlParams.get('utm_campaign') || '',
        utm_content: urlParams.get('utm_content') || '',
        utm_term: urlParams.get('utm_term') || '',
      }
    },

    startRequest() {
      if (this.isAgree) {
        this.hasNameError = false
        this.hasPhoneError = false
        this.hasEmailError = false
        this.validateData()

        // Запрос на презентацию
        if (this.isPresentation) {
          if (!this.hasValidationPresentationErrors) {
            this.isLoading = true

            const utmParams = this.getUtmParams()
            let formData = new FormData()

            formData.append('name', this.name)
            formData.append('email', this.email)
            this.phone
              ? formData.append('phone', this.phone)
              : formData.append('phone', 'Не указан')
            formData.append('no_calls', this.isAgreeCall)
            formData.append('subject', 'Запрос на презентацию')

            Object.keys(utmParams).forEach(key => {
              formData.append(key, utmParams[key])
            })
            axios
              .post(process.env.VUE_APP_API_URL, formData)
              .then(() => {
                this.$emit('requested', 'success')
                this.$refs.nameInput.reset()
                this.$refs.phoneInput.reset()
                this.$refs.emailInput.reset()
              })
              .catch(() => {
                this.$emit('requested', 'error')
              })
              .finally(() => {
                this.isLoading = false
                this.name = ''
                this.email = ''
                this.phone = ''
              })
          }
        }

        // Запрос на контактную форму, или запрос демонстрации
        if (!this.hasValidationErrors && !this.isPresentation) {
          this.isLoading = true

          const utmParams = this.getUtmParams()
          let formData = new FormData()

          formData.append('name', this.name)
          formData.append('email', this.email)
          formData.append('phone', this.phone)
          this.isDemonstration
            ? formData.append('subject', 'Запрос демонстрации')
            : formData.append('subject', 'Контактная форма')

          Object.keys(utmParams).forEach(key => {
            formData.append(key, utmParams[key])
          })
          axios
            .post(process.env.VUE_APP_API_URL, formData)
            .then(() => {
              this.$emit('requested', 'success')
              this.$refs.nameInput.reset()
              this.$refs.phoneInput.reset()
              this.$refs.emailInput.reset()
            })
            .catch(() => {
              this.$emit('requested', 'error')
            })
            .finally(() => {
              this.isLoading = false
              this.name = ''
              this.email = ''
              this.phone = ''
            })
        }
      }
    },
  },
}
</script>

<style lang="sass" scoped>
.form-callback__input
  border-radius: 10px

.form-callback__error
  margin-bottom: 2rem
  margin-right: 22px
  @media (min-width: 1280px)
    margin-right: 0
  @media (max-width: 1279px)
    width: 100%

.form-callback__button
  display: flex
  justify-content: center
  align-items: center
  border: none
  outline: none
  border-radius: 50px
  background-color: transparent
  cursor: pointer

.form-callback__checkbox_link
  border-bottom: 1px solid #A7ADB6

.checkbox__first
  margin-bottom: 16px !important

::v-deep .el-checkbox__input.is-checked .el-checkbox__inner
  background: $buttonPrimary

::v-deep .el-checkbox__inner
  width: 16px
  height: 16px
  &::after
    height: 8px
    left: 5px

::v-deep .el-checkbox
  color: #A7ADB6
  a
    color: #A7ADB6

::v-deep .el-checkbox__input.is-checked+.el-checkbox__label
  color: #A7ADB6
  a
    color: #A7ADB6

::v-deep .el-checkbox__label
  font-family: $secondaryFont
  font-weight: 400
  font-size: 1.2rem
  line-height: 1.6rem

.modal
  position: fixed
  top: 0
  left: 0
  width: 100%
  height: 100%
  background-color: rgba(0, 0, 0, 0.5)
  display: flex
  justify-content: center
  align-items: center
  z-index: 9999

.loader
  border: 8px solid #f3f3f3
  border-top: 8px solid #3498db
  border-radius: 50%
  width: 50px
  height: 50px
  animation: spin 1s linear infinite

@keyframes spin
  0%
    transform: rotate(0deg)
  100%
    transform: rotate(360deg)
</style>
