<template>
  <div class="wrapper">
    <div class="more">
      <div class="more__title" @click="handleClick">
        {{ title }}
      </div>
      <div class="more__arrow">
        <svg
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M1.75 14.25L14.25 1.75"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M1.75 1.75H14.25V14.25"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'MoreButton',
  props: {
    title: {
      type: String,
      default: '',
    },
    isButton: {
      type: Boolean,
      default: true,
    },
  },
  methods: {
    handleClick() {
      this.isButton ? this.$emit('show-form') : this.$emit('scroll-to')
    },
  },
}
</script>

<style lang="sass" scoped>
.wrapper
  display: flex
  justify-content: flex-end
.more
  display: flex
  align-items: center
  cursor: pointer
  &__title
    font-family: $primaryFont
    font-weight: 500
    font-size: 1.6rem
    line-height: 2.2rem
    margin-right: 0.5rem
    color: $buttonPrimary
    transition: color .3s ease
  &__arrow
    display: flex
    justify-content: center
    align-items: center
    width: 3rem
    height: 3rem
    svg
      width: 1.6rem
      height: 1.6rem
      stroke: $buttonPrimary
      transition: stroke .3s ease

  &:hover
    .more__title
      color: $buttonHover
    svg
      stroke: $buttonHover
  &:active
    .more__title
      color: $buttonClicked
    svg
      stroke: $buttonClicked

</style>
