export const topMenu = {
  menuItems: [
    {
      label: 'Партнеры',
      component: 'QueuePartners',
    },
    {
      label: 'Что решает ЭО',
      component: 'QueueDecision',
    },
    {
      label: 'Описание продукта',
      component: 'QueueDescription',
    },
    {
      label: 'Сроки и цена',
      component: 'QueueTerms',
    },
    {
      label: 'Кейс',
      component: 'QueueCase',
    },
    {
      label: 'Контакты ',
      component: 'FooterComponent',
    },
  ],
  pushToHome: 'На главную',
  pushToLegasy: 'Правовая информация',
}

export const headerBlock = {
  image: 'header.png',
  title: 'Система <br> электронной очереди',
  description:
    'Готовое программное решение для планирования и управления входящими потоками большегрузного транспорта.',
  button: 'Узнать больше',
  buttonPresentation: 'Получить презентацию',
  buttonDemonstration: 'Запросите демонстрацию',
}

export const decisionBlock = {
  title: 'Что решает сервис  <br> электронная очередь',
  tiles: [
    {
      title: '01',
      text: 'Большое скопление транспорта перед терминалом выгрузки',
    },
    {
      title: '02',
      text: 'Долгое ожидание выгрузки для транспорта',
      background: true,
    },
    {
      title: '03',
      text: 'Проблема с вызовом нужного транспорта / сырья на выгрузку',
    },
    {
      img: 'decision1.png',
    },
    {
      img: 'decision2.png',
    },
    {
      title: '04',
      text: 'Простой терминала и неравномерная нагрузка',
    },
    {
      title: '05',
      text: 'Отсутствие планирования нагрузки на терминал',
      background: true,
    },
  ],
}

export const decisionBlockFirst = {
  decisions: [
    {
      text: 'Предварительная запись в электронную очередь ',
    },
    {
      text: 'Бесплатная, круглосуточная техподдержка для водителей',
    },
    {
      text: 'Автоматический вызов автомобилей из места ожидания',
    },
    {
      text: 'Бесплатные доработки системы для вас в 90% случаев',
    },
    {
      text: 'Интеграция с вашей внутренней системой учета',
    },
    {
      text: 'Бесплатная интеграция системы, обучение и личный менеджер поддержки',
    },
  ],
}
// заголовки форм (кроме футера)
export const formBlock = {
  title: 'Расскажем больше!',
  description: 'Оставьте свои контактные данные и менеджер свяжется с вами ',
}

export const formBlockPresentation = {
  title: 'Вышлем презентацию на вашу почту',
  description:
    'После того как вы оставите заявку, мы отправим на вашу почту подробную презентацию нашего продукта в PDF формате.',
}

export const formBlockDemonstration = {
  title: 'Продемонстрируем продукт в онлайн формате',
  description:
    'После того как вы оставите заявку, мы свяжемся с вами чтобы договориться о удобном времени демонстрации в режиме онлайн конференции.',
}
// данные для самой формы
export const formData = {
  placeholders: {
    name: 'Как к вам обращаться',
    phone: 'Ваш телефон',
    email: 'Ваша почта',
  },
  placeholdersDemonstration: {
    name: 'Как к вам обращаться',
    phone: 'Ваш телефон',
    email: 'Ваша почта',
  },
  placeholdersPresentation: {
    name: 'Как к вам обращаться',
    phone: 'Ваш телефон (не обязательно)',
    email: 'E-mail куда вышлем презентацию',
  },
  button: 'Отправить',
  checkbox: {
    text: 'Я согласен с ',
    textPresentation: 'Не хочу чтобы мне звонили',
    link: 'политикой конфиденциальности',
    url: '/policy.pdf',
  },
}

export const descriptionBlock = {
  title: 'После внедрения электронной очереди',
  cards: [
    {
      icon: 'planning',
      title: 'Планирование ',
      content: 'Планирование объема поставок на терминал',
    },
    {
      icon: 'quotes',
      title: 'Выставление квот ',
      content: 'На заезд в терминал и разгрузку авто',
    },
    {
      icon: 'maintenance',
      title: 'Сопровождение',
      content: 'Сопровождение водителей от бронирования таймслота до разгрузки',
    },
    {
      icon: 'transport',
      title: 'Вывоз транспорта',
      content: 'Автоматический вызов транспорта на терминал при помощи СМС',
    },
    {
      icon: 'management',
      title: 'Управление',
      content: 'Управление поступлением нужного вида и объема сырья',
    },
    {
      icon: 'data',
      title: 'Сбор данных',
      content: 'Сбор и хранение данных по рейсам',
    },
  ],
}

export const indicatorsBlock = {
  title: 'Показатели при <br> внедрении ЭО',
  button: 'Узнать подробнее',
  tiles: [
    {
      title: '> 50%',
      text: 'Уменьшается скопление транспорта перед терминалом',
    },
    {
      title: '> 60% ',
      text: 'Уменьшается время ожидания транспорта в очереди на разгрузку',
    },
    {
      title: '> 20%',
      text: 'Увеличивается объем приемки транспорта на терминале',
    },
    {
      title: '> 10%',
      text: 'Уменьшается время простоя терминала без транспорта',
    },
  ],
}

export const termsBlock = {
  title: 'Сроки, этапы и стоимость интеграции ЭО на терминал',
  tiles: [
    {
      subtitle: '1-2 недели- подготовка',
      tasks: [
        'Анализ бизнес процессов терминала',
        'Адаптация ЭО для интеграции в конкретном терминале',
        'Установка оборудования и обучение персонала терминала',
      ],
    },
    {
      subtitle: '1-2 недели - запуск',
      tasks: [
        'Тестовый запуск',
        'Запуск работы электронной очереди и полное сопровождение на первых этапах',
      ],
    },
  ],
  button: 'Стоимость для терминала <span>&nbsp;1000&nbsp;</span> рублей в год',
}

export const caseBlock = {
  image: 'case.png',
  subtitle: 'Кейс с Черкизово ',
  items: [
    {
      question: 'Когда внедрили',
      answer: '2021 год',
    },
    {
      question: 'Машин проехало через ЭО в Черкизово',
      answer: '> 30 000',
    },
    {
      question: 'Какие показатели улучшились',
      answer: '',
    },
    {
      question: 'На скольких терминалах уже работает ЭО',
      answer: '5',
    },
  ],
  comments: [
    {
      name: 'Перевертайло Сергей',
      position: 'Начальник смены терминала Латная',
      text: 'После внедрения ЭО мы избавились от скопления автомобилей на обочинах дорог при въезде на терминал Латная. Теперь мы можем гибко управлять вызовом нужного транспорта на площадку и оперативно останавливать выезжающий транспорт в случае непридвиденных поломок. Мы избавились от очередей и теперь умеем управлять временем заезда, вызова разного вида транспорта с необходимыми культурами.',
    },
    {
      name: 'Андрей Александрович',
      position: 'Отдел закупок терминала',
      text: 'Благодаря внедрению ЭО теперь мы выстраиваем приоритеты по заезду транспорта. ЭО помогает обьяснить порядок заезда на выгрузку поставщикам и принимать решения в спорных ситуациях. Теперь мы полностью контролируем транспорт.',
    },
  ],
}

export const footerBlock = {
  title: 'Контакты',
  contacts: [
    {
      title: 'Адрес:',
      value:
        '121205, город Москва,<br>  Внутренний территориальный городской муниципальный округ Можайский, <br> тер. Сколково инновационного центра, б-р Большой, д. 42, стр. 1, пом. 138',
    },
    {
      title: 'Телефон:',
      value:
        '<a href="tel:+79888904848">+7 (988) 890-48-48</a>',
    },
  ],
  map: 'footer-new-map.png',
  copyright: 'ООО Порт Транзит ©',
}

export const partnersBlock = {
  title: 'Партнеры, которые уже <br> используют продукт',
  partners: [
    'resurce.png',
    'aprotek.png',
    'cherkizovo.png',
    'nkhp.png',
    'ksk.png',
    'step.png',
    'azt.png',
  ],
}

export const modalRequestBlock = {
  success: {
    title: 'Спасибо, ваша заявка отправлена!',
    description: 'В ближайшее время мы с вами свяжемся!',
  },
  error: {
    title: 'Возникли технические проблемы!',
    description: 'Попробуйте отправить заявку еще раз',
  },
  button: 'Вернуться на сайт',
}

export const legalInformation = {
  generalInformation: {
    title:
      'Общая информация о программном обеспечении сервисов Порт транзит (Программный комплекс "Подскок")',
    description:
      'Программное обеспечение “Подскок” предназначено для диспетчерского и логистического управления транспортом. Включает в себя интерфейс электронной очереди для перевозчика в виде <a href="/legal/instruction.pdf" target="_blank" class="information__text">мобильного приложения “Зерновозы”</a>  и сайта <a href="https://zernovozam.ru/" target="_blank" class="information__text">zernovozam.ru</a> , а для компаний-стивидоров веб-интерфейс с функционалом алгоритма сортировки и квотирования объемов поставляемого груза, оцифровки документов, статистического анализа.',
  },
  functionalCharacteristics: {
    title: 'Функциональные характеристики платформы',
    description:
      'Для перевозчика/заказчика перевозки представлен интерфейс электронной очереди в виде мобильного приложения, сайта и sms-заявки. Электронная очередь формирует таймслот в который перевозчику требуется прибыть в пункт разгрузки. Таймслот назначается исходя из квоты выставленной терминалом приема груза в зависимости от качества, поставщика и размера согласованного объема.\n' +
      'Для терминалов предоставлен веб-интерфейс в котором реализованы механизмы распределения квот по качеству, объему, поставщикам, количеству и датам. Раздел статистики и аналитики формирует отчеты по качественно-количественным показателям и аналитику по предполагаемому количеству автомобилей от электронной очереди. Реализовано API для взаимодействия с учетными программами терминалов для обмена оцифрованными документами.\n',
  },
  contractOffer: {
    title: 'Договор-оферта и стоимость',
    descriptionTop:
      'Договор-оферта "Порт транзит" на использование Программного комплекса "Подскок"  постоянно размещено по адресу <a href="/legal/oferta.pdf" target="_blank" class="information__text">https://landing.port-tranzit.ru/legal/oferta.pdf</a> (далее – Оферта) и является обязательным документом для всех лиц, использующих ПО и сервисы Порт транзит.\n' +
      '\n' +
      'При использовании ПО мы собираем и обрабатываем Ваши персональные данные в соответствии с <a href="/legal/policy.pdf" target="_blank" class="information__text">Политикой обработки персональных данных</a>. \n' +
      '\n' +
      'Если вы не согласны с условиями Оферты и Политики обработки персональных данных, немедленно прекратите использование ПО.\n' +
      '\n' +
      'Стоимость сервисов и продуктов: \n',
    descriptionBottom:
      'Для оказания технической поддержки выделен единый номер телефона +7 (962) 860-48-27 и оперативный по текущей ситуации +7 (918) 481-45-09.',
  },
  documents: {
    title:
      'Документы, описывающие условия использования программного обеспечения',
    description:
      '- <a href="/legal/webinstruction.pdf" target="_blank" class="information__text">Руководство пользователя веб приложением “Зерновозы”</a>' +
      '\n' +
      '- <a href="/legal/ptdevprocess.pdf" target="_blank" class="information__text">Информация о процессах разработки и поддержки ООО «Порт транзит»</a>' +
      '\n' +
      '- <a href="/legal/instruction.pdf" target="_blank" class="information__text">Руководство пользователя мобильным приложением “Зерновозы”</a>' +
      '\n' +
      'ПО доступна в формате интернет-сервиса. Специальные действия по установке платформы на стороне пользователя не требуются.\n' +
      'ПО разделено на несколько интерфейсов взаимодействия с сервисами электронной очереди.\n' +
      '<a href="/legal/instruction.pdf" target="_blank" class="information__text">Мобильное приложение "Зерновозы"</a> \n' +
      'Сайт <a href="https://zernovozam.ru/" target="_blank" class="information__text">zernovozam.ru</a> \n' +
      'Программный комплекс "Подскок" для стивидорных компаний\n' +
      '\n' +
      'В публичном доступе находятся мобильное приложение и сайт <a href="https://zernovozam.ru/" target="_blank" class="information__text">zernovozam.ru</a> \n' +
      'Для использования Сервисов пройдите регистрацию на странице <a href="https://zernovozam.ru/user/registration" target="_blank" class="information__text">https://zernovozam.ru/user/registration</a> или в <a href="/legal/instruction.pdf" target="_blank" class="information__text">Мобильном приложении "Зерновозы"</a>\n',
  },
  developmentDirections: {
    title: 'Направления разработки и поддержание жизненного цикла',
    description:
      'Основные усилия ООО “Порт транзит” в области технологических разработок направлены на создание замкнутой, автоматизированной и самодостаточной системы логистического сопровождения транспорта и сопутствующих сервисов, обеспечивающих клиентам персонализированный подход к доставке грузов, охватывающий полный цикл инструментов и консолидированной информации для комфортной работы.\n' +
      'Поддержание жизненного цикла ПО обеспечивается за счет его сопровождения и проведения обновлений в соответствии с планом разработки ПО. Все новые обновления проходят через систему контроля версий и покрыты тестами. Неисправности в ходе эксплуатации ПО могут быть исправлены в ходе обновления компонентов ПО.\n',
  },
}
