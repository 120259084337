<template>
  <section ref="anchor" class="indicators">
    <div class="indicators__left">
      <div>
        <div
          v-html-safe="content.title"
          class="indicators__left-title font-title"
        />
      </div>
      <more-button :title="content.button" v-on="$listeners" />
    </div>
    <div class="indicators__right">
      <div
        v-for="(item, index) in content.tiles"
        :key="index"
        class="indicators__right-tile"
      >
        <div class="font-indicators">{{ item.title }}</div>
        <div class="font-small">{{ item.text }}</div>
      </div>
    </div>
  </section>
</template>

<script>
import { indicatorsBlock } from '@/content/data'
import offsetMixin from '@/mixins/offsetMixin'
import MoreButton from '@/components/common/MoreButton'

export default {
  name: 'QueueIndicators',
  components: { MoreButton },
  mixins: [offsetMixin],
  data() {
    return {
      content: indicatorsBlock,
    }
  },
}
</script>

<style lang="sass" scoped>
.indicators
  display: flex
  border-top: 1px solid $backgroundGray
  border-bottom: 1px solid $backgroundGray
  @media (max-width: 1279px)
    flex-direction: column
  &__left
    display: flex
    flex-direction: column
    justify-content: space-between
    width: 50%
    padding: 4rem 4rem 3.2rem $desktopHorizontalPaddings
    @media (max-width: 1279px)
      width: 100%
      padding: 3.2rem 1.4rem 2.5rem $mobileHorizontalPaddings
    &-title
      @media (max-width: 1279px)
        margin-bottom: 4.5rem
  &__right
    display: flex
    flex-wrap: wrap
    width: 50%
    padding-right: 18.75vw
    @media (max-width: 1279px)
      width: 100%
      padding: 0
    &-tile
      display: flex
      flex-direction: column
      justify-content: space-between
      width: 50%
      @media (min-width: 1280px)
        height: 31rem
        padding: 4rem 5rem 3.8rem 3.2rem
        &:first-of-type
          border-left: 1px solid $backgroundGray
          border-bottom: 1px solid $backgroundGray
          border-right: 1px solid $backgroundGray
        &:nth-of-type(2)
          border-bottom: 1px solid $backgroundGray
          border-right: 1px solid $backgroundGray
        &:nth-of-type(3)
          border-left: 1px solid $backgroundGray
          border-right: 1px solid $backgroundGray
        &:nth-of-type(4)
          border-right: 1px solid $backgroundGray
      @media (max-width: 1279px)
        height: 21.8rem
        padding: 2rem 1rem 3rem $mobileHorizontalPaddings
        border-top: 1px solid $backgroundGray
        &:nth-of-type(2n+1)
          border-right: 1px solid $backgroundGray
</style>
