import { render, staticRenderFns } from "./QueueCase.vue?vue&type=template&id=bcd16684&scoped=true"
import script from "./QueueCase.vue?vue&type=script&lang=js"
export * from "./QueueCase.vue?vue&type=script&lang=js"
import style0 from "./QueueCase.vue?vue&type=style&index=0&id=bcd16684&prod&lang=sass&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "bcd16684",
  null
  
)

export default component.exports