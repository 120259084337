<template>
  <modal-wrapper :visible="visible" v-on="$listeners">
    <template slot="content">
      <div class="font-subtitle">{{ content.title }}</div>
      <div class="font-small description">{{ content.description }}</div>
      <lead-form
        :is-demonstration="isDemonstration"
        :is-presentation="isPresentation"
        v-on="$listeners"
      />
    </template>
  </modal-wrapper>
</template>

<script>
import { formBlockPresentation } from '@/content/data'
import LeadForm from '@/components/common/Form/LeadForm'
import ModalWrapper from '@/components/common/Modals/ModalWrapper'

export default {
  name: 'ModalRequestResult',
  components: { ModalWrapper, LeadForm },
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    isDemonstration: {
      type: Boolean,
      default: false,
    },
    isPresentation: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      content: formBlockPresentation,
    }
  },
}
</script>

<style lang="sass" scoped>
.description
  margin-top: .8rem
  margin-bottom: 2.3rem

.font-subtitle
  @media (max-width: 1279px)
    width: 80%

::v-deep .form-callback
  display: flex
  flex-direction: column
  &__input
    margin-bottom: 2rem

  &__button
    height: 5rem
    background: $buttonPrimary
    color: $textWhite
    &:hover
      background: $buttonHover
    &:active
      background: $buttonClicked

    &.disabled
      background: $buttonDisabled
      cursor: not-allowed
      color: #B3B8C0
      &:hover
        background: $buttonDisabled
      &:active
        background: $buttonDisabled

  &__checkbox
    margin-bottom: 3.3rem

::v-deep .input
  background: #F6F8F8
  &__content-input
    background: #F6F8F8
</style>
