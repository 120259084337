<template>
  <section ref="anchor" class="case">
    <div class="case__block mod-image">
      <img
        :src="`/img/${content.image}`"
        alt="case"
        class="case__block-image"
      />
    </div>
    <div class="case__block mod-info">
      <div>
        <div class="case__block-subtitle font-subtitle">
          {{ content.subtitle }}
        </div>
        <div
          v-for="(item, index) in content.items"
          :key="index"
          class="case__block-item"
        >
          <div class="font-small">{{ item.question }}</div>
          <div v-if="item.answer.length" class="font-medium item__answer">
            {{ item.answer }}
          </div>
          <more-button
            v-else
            title="Показать"
            :is-button="false"
            class="mod-shrink"
            @scroll-to="goToSection('QueueIndicators')"
          />
        </div>
      </div>
    </div>
    <div
      v-for="(comment, index) in content.comments"
      :key="index"
      class="case__block comment"
    >
      <div class="comment__title">
        <div class="comment__title-initials font-medium">
          {{ getInitials(comment.name) }}
        </div>
        <div>
          <div class="comment__title-name">{{ comment.name }}</div>
          <div class="comment__title-position">{{ comment.position }}</div>
        </div>
      </div>
      <div class="comment__content">
        {{ comment.text }}
      </div>
    </div>
  </section>
</template>

<script>
import { caseBlock } from '@/content/data'
import MoreButton from '@/components/common/MoreButton'
import offsetMixin from '@/mixins/offsetMixin'
import menuMixin from '@/mixins/menuMixin'
export default {
  name: 'QueueCase',
  components: {
    MoreButton,
  },
  mixins: [menuMixin, offsetMixin],
  data() {
    return {
      content: caseBlock,
    }
  },
  methods: {
    getInitials(fio) {
      let arr = fio.split(' ')

      return arr.reduce((acc, item) => acc + item[0], '')
    },
  },
}
</script>

<style lang="sass" scoped>
.case
  display: flex
  flex-wrap: wrap
  padding: 0 $desktopHorizontalPaddings
  border-top: 1px solid $backgroundGray
  border-bottom: 1px solid $backgroundGray
  @media (max-width: 1279px)
    padding: 0 $mobileHorizontalPaddings
    flex-direction: column
  &__block
    display: flex
    flex-direction: column
    width: 50%
    padding: 3.2rem
    border-left: 1px solid $backgroundGray
    &:nth-child(3), &:nth-child(4)
      border-top: 1px solid $backgroundGray
    &:nth-child(2n)
      border-right: 1px solid $backgroundGray
    @media (max-width: 1279px)
      width: 100%
      padding: 3.2rem 0
      border: none
      &:nth-child(2n)
        border-right: none
    &.mod-info
      display: flex
      flex-direction: column
      justify-content: space-between
      width: 50%
      padding: 6rem 3.2rem 3.6rem
      border-right: 1px solid $backgroundGray
      @media (max-width: 1279px)
        order: 0
        width: 100%
        padding: 4rem 0 0
        border-right: none
        border-bottom: 1px solid $backgroundGray
    &.mod-image
      @media (max-width: 1279px)
        order: 1

    &-image
      width: 100%
      height: 40rem
      object-fit: cover
      object-position: center
      border-radius: 20px
      @media (max-width: 1439px) and (min-width: 1280px)
        height: 49rem

    &-subtitle
      margin-bottom: 1.6rem
      @media (max-width: 1279px)
        margin-bottom: 1.3rem
    &-item
      display: flex
      justify-content: space-between
      align-items: center
      border-top: 1px solid $backgroundGray
      padding: 2.6rem 0
      @media (max-width: 1279px)
        padding: 2rem 0
      &:nth-child(2)
        border-top: none

      .item__answer
        flex-shrink: 0
        width: 31%
        margin-left: 2rem
        font-weight: 600
        text-align: right
        &.mod-link
          color: $buttonPrimary
          cursor: pointer
          transition: color .3s ease
          &:hover
            color: $buttonHover
      .mod-shrink
        flex-shrink: 0
        width: 31%
        margin-left: 2rem
        @media (min-width: 1280px) and (max-width: 1439px)
          width: 34%
        @media (max-width: 1279px)
          width: 32%
          margin-left: 1rem

  .comment
    @media (max-width: 1279px)
      order: 2
    &__title
      display: flex
      align-items: center
      margin-bottom: 2.2rem
      @media (max-width: 1279px)
        margin-bottom: 1.6rem

      &-initials
        display: flex
        justify-content: center
        align-items: center
        width: 4.8rem
        height: 4.8rem
        margin-right: 1.4rem
        border-radius: 50%
        background: #E0E5E6
        @media (max-width: 1279px)
          margin-right: 1.2rem
      &-name
        font-family: $primaryFont
        font-weight: 400
        font-size: 1.8rem
        line-height: 2.4rem
        color: $textPrimary
        margin-bottom: .2rem
        @media (max-width: 1279px)
          font-size: 1.6rem
          line-height: 2rem
      &-position
        font-family: $secondaryFont
        font-weight: 400
        font-size: 1.4rem
        line-height: 1.8rem
        color: $textGray
        @media (max-width: 1279px)
          font-size: 1.2rem
          line-height: 1.6rem
    &__content
      font-family: $secondaryFont
      font-weight: 400
      font-size: 1.4rem
      line-height: 2.1rem
      color: $textSecondary
</style>
