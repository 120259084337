<template>
  <div>
    <div :class="['input', inputStatus, { error: hasError }]" @click="start">
      <div :class="['input__content', inputStatus, { error: hasError }]">
        <div :class="['input__content-prompt', inputStatus]">
          {{ title }}
        </div>
        <input
          v-if="mask"
          ref="input"
          v-model="inputContent"
          v-mask="mask"
          :class="['input__content-input', inputStatus, { error: hasError }]"
          @blur="inputChanged"
        />
        <input
          v-else
          ref="input"
          v-model="inputContent"
          :class="['input__content-input', inputStatus, { error: hasError }]"
          @blur="inputChanged"
        />
      </div>
    </div>
    <transition>
      <div v-if="hasError" class="input__error">
        <div class="input__error-image">
          <img src="@/assets/img/new-error.svg" alt="phone" />
        </div>
        <p class="input__error-text">{{ errorText }}</p>
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  name: 'DoubleInput',
  props: {
    title: {
      type: String,
      default: '',
    },
    mask: {
      type: String,
      default: '',
    },
    errorText: {
      type: String,
      default: '',
    },
    hasError: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      inputContent: '',
      inputStatus: '',
    }
  },
  methods: {
    start() {
      this.inputStatus = 'active'
      this.$refs.input.focus()
      this.$emit('input-focus', this.inputContent)
    },
    inputChanged() {
      if (!this.inputContent.length) {
        this.inputStatus = ''
      }
      this.$emit('input-changed', this.inputContent)
    },
    reset() {
      this.inputStatus = ''
      this.inputContent = ''
    },
  },
}
</script>

<style lang="sass" scoped>
.input
  display: flex
  width: 100%
  height: 5rem
  margin-bottom: 0.2rem
  border-radius: 0.8rem
  cursor: pointer

  &.error
    border: 1px solid #F9375A

  &__content
    flex: 1
    display: flex
    flex-direction: column
    justify-content: center
    padding: 0.6rem 1.2rem
    border-radius: 0.8rem

    &-input
      width: 100%
      height: 0
      padding: 0
      border-width: 0
      outline: none
      font-family: $secondaryFont
      font-weight: 400
      font-size: 0
      transition: height .3s ease
      color: $textPrimary
      &.active
        height: 2.5rem
        font-size: 1.4rem

    &-prompt
      font-family: $secondaryFont
      font-weight: 400
      font-size: 1.4rem
      color: $textSecondary
      transition: all .3s ease
      &.active
        margin-bottom: .4rem
        font-size: 1.2rem
        line-height: 1.6rem

  &__error
    display: flex
    align-items: center
    color: #F9375A
    &-image
      margin: 5px 9px 0 0
    &-text
      margin-top: 5px
</style>
