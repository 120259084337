<template>
  <section class="menu">
    <div class="menu__header">
      <div class="menu__header-logo">
        <div class="picture">
          <svg
            width="162"
            height="25"
            viewBox="0 0 260 40"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M51.7215 8.68652C54.253 8.68652 56.266 9.35815 57.73 10.7014C59.194 12.0447 59.926 13.8764 59.926 16.1966V16.5934C59.926 18.9442 59.194 20.8064 57.73 22.1497C56.266 23.4929 54.253 24.1646 51.7215 24.1646H42.9985V32.3157H38.088V8.68652H51.7215ZM54.0395 18.6999C54.619 18.242 54.8935 17.5398 54.8935 16.624V16.2271C54.8935 15.3418 54.619 14.6701 54.0395 14.1817C53.46 13.7238 52.6365 13.4795 51.569 13.4795H42.968V19.3716H51.569C52.667 19.4021 53.46 19.1579 54.0395 18.6999Z"
              fill="white"
            />
            <path
              d="M65.9345 31.6441C64.44 30.9114 63.2505 29.8429 62.4575 28.4691C61.634 27.0953 61.2375 25.4773 61.2375 23.5845V23.1266C61.2375 21.2033 61.634 19.5547 62.427 18.1504C63.22 16.7461 64.379 15.6776 65.8735 14.9449C67.368 14.2122 69.1675 13.8153 71.2415 13.8153C73.285 13.8153 75.054 14.1817 76.5485 14.9449C78.043 15.6776 79.1715 16.7461 79.9645 18.1504C80.7575 19.5547 81.154 21.2033 81.154 23.1266V23.5845C81.154 25.4468 80.7575 27.0953 79.9645 28.4691C79.1715 29.8429 78.0125 30.9114 76.5485 31.6441C75.054 32.3768 73.285 32.7431 71.2415 32.7431C69.2285 32.7431 67.4595 32.3768 65.9345 31.6441ZM74.9625 26.8511C75.847 26.0879 76.3045 24.9888 76.3045 23.5845V23.1876C76.3045 21.7223 75.8775 20.5927 74.993 19.799C74.1085 19.0052 72.858 18.6083 71.2415 18.6083C69.5945 18.6083 68.344 19.0052 67.4595 19.7684C66.575 20.5622 66.148 21.6917 66.148 23.1571V23.554C66.148 24.9583 66.6055 26.0573 67.49 26.8205C68.3745 27.5838 69.625 27.9806 71.2415 27.9806C72.858 27.9806 74.078 27.6143 74.9625 26.8511Z"
              fill="white"
            />
            <path
              d="M96.221 14.2733V18.8831H92.561C90.5175 18.8831 89.0535 19.4326 88.23 20.5622V32.2852H83.5025V14.2733H87.925L88.047 15.9218C89.4195 14.8228 91.1275 14.2733 93.171 14.2733H96.221Z"
              fill="white"
            />
            <path
              d="M108.97 18.8831V25.7215C108.97 26.3626 109.153 26.8816 109.489 27.2174C109.824 27.5532 110.312 27.7364 110.953 27.7364H114.826V32.3157H110.343C108.452 32.3157 106.988 31.7662 105.89 30.6366C104.792 29.5071 104.243 28.0112 104.243 26.0879V18.8831H98.7525V14.2733H103.145C103.602 14.2733 103.907 14.1817 104.09 13.9985C104.273 13.8153 104.365 13.449 104.365 12.9605V9.48027H108.97V14.2733H115.802V18.8831H108.97Z"
              fill="white"
            />
            <path
              d="M117.876 13.5406V8.68652H139.623V13.5406H131.235V32.3157H126.264V13.5406H117.876Z"
              fill="white"
            />
            <path
              d="M154.385 14.2733V18.8831H150.725C148.681 18.8831 147.217 19.4326 146.394 20.5622V32.2852H141.666V14.2733H146.089L146.211 15.9218C147.583 14.8228 149.291 14.2733 151.335 14.2733H154.385Z"
              fill="white"
            />
            <path
              d="M172.318 15.9523C173.965 17.3567 174.789 19.341 174.789 21.8749V32.3157H170.367L170.214 30.6366C168.628 32.0409 166.371 32.7736 163.474 32.7736C161.095 32.7736 159.234 32.2852 157.953 31.3388C156.641 30.3924 156.001 29.0491 156.001 27.2785C156.001 24.3172 157.892 22.5771 161.644 22.1191L169.97 21.1117C169.787 20.2569 169.299 19.6158 168.506 19.1579C167.713 18.6999 166.645 18.4862 165.273 18.4862C163.931 18.4862 162.894 18.6999 162.131 19.1273C161.369 19.5547 160.972 20.1348 160.972 20.8675V20.9896H156.123V20.8675C156.123 19.4631 156.52 18.2115 157.282 17.1735C158.045 16.105 159.112 15.3112 160.515 14.7312C161.918 14.1512 163.534 13.8764 165.395 13.8764C168.353 13.8459 170.671 14.548 172.318 15.9523ZM167.591 27.8585C168.72 27.3701 169.543 26.729 170.031 25.8742V24.7446L162.864 25.5994C162.193 25.6605 161.674 25.8436 161.308 26.1184C160.911 26.3931 160.729 26.729 160.729 27.1258C160.729 28.0722 161.705 28.5607 163.687 28.5607C165.151 28.5607 166.463 28.3165 167.591 27.8585Z"
              fill="white"
            />
            <path
              d="M194.614 16.2576C196.078 17.8757 196.81 20.2569 196.81 23.4319V32.3157H192.052V23.4319C192.052 21.8749 191.656 20.6843 190.893 19.86C190.131 19.0357 188.972 18.6083 187.508 18.6083C186.471 18.6083 185.556 18.8526 184.732 19.3716C183.909 19.8905 183.268 20.5927 182.841 21.478V32.3157H178.083V14.2733H182.475L182.597 16.0134C184.031 14.6091 185.891 13.9069 188.148 13.8459C190.985 13.8459 193.15 14.6396 194.614 16.2576Z"
              fill="white"
            />
            <path
              d="M217.215 18.6999L206.509 27.8891H217.215V32.3157H200.074V27.8891L210.749 18.7305H200.074V14.2733H217.215V18.6999Z"
              fill="white"
            />
            <path
              d="M220.57 11.312C220.021 10.7625 219.746 9.99926 219.746 9.0834C219.746 8.16754 220.021 7.43485 220.57 6.8548C221.119 6.30529 221.82 6 222.705 6C223.589 6 224.321 6.27476 224.84 6.8548C225.389 7.40432 225.633 8.16754 225.633 9.0834C225.633 9.99926 225.358 10.7319 224.809 11.312C224.26 11.8615 223.559 12.1668 222.674 12.1668C221.82 12.1363 221.119 11.8615 220.57 11.312ZM225.084 32.3157H220.356V14.2733H225.084V32.3157Z"
              fill="white"
            />
            <path
              d="M238.199 18.8831V25.7215C238.199 26.3626 238.381 26.8816 238.717 27.2174C239.052 27.5532 239.54 27.7364 240.181 27.7364H244.055V32.3157H239.571C237.68 32.3157 236.216 31.7662 235.118 30.6366C234.02 29.5071 233.471 28.0112 233.471 26.0879V18.8831H227.981V14.2733H232.373C232.83 14.2733 233.135 14.1817 233.318 13.9985C233.501 13.8153 233.593 13.449 233.593 12.9605V9.48027H238.229V14.2733H245V18.8831H238.199Z"
              fill="white"
            />
            <path
              d="M6.978 8.74805V12.8084C6.978 13.8158 6.2155 14.6401 5.2395 14.7317C5.1785 14.7317 1 14.7317 1 14.7317V32.2856H18.812V28.2253C18.812 27.2179 19.5745 26.3936 20.5505 26.302C20.6115 26.302 24.79 26.302 24.79 26.302V8.74805H6.978ZM16.616 15.0675C17.6225 15.0675 18.446 15.8918 18.446 16.8992V24.1345C18.446 25.1419 17.6225 25.9662 16.616 25.9662H9.1435C8.137 25.9662 7.3135 25.1419 7.3135 24.1345V16.8992C7.3135 15.8918 8.137 15.0675 9.1435 15.0675H16.616Z"
              fill="#4CC0CC"
            />
          </svg>
        </div>
      </div>
      <div class="menu__header-hamburger" @click="showDropdown">
        <img
          v-if="isDropdownHidden"
          src="@/assets/img/hamburger.svg"
          alt="menu"
        />
        <img v-else src="@/assets/img/menu-close.svg" alt="menu" />
      </div>
    </div>
    <div :class="['menu__dropdown', { hidden: isDropdownHidden }]">
      <div v-if="showMenuItems" class="menu__dropdown-items">
        <div
          v-for="(item, index) in menu.menuItems"
          :key="index"
          class="font-small item"
          @click="swipeToBlock(item.component)"
        >
          <div class="item__title">{{ item.label }}</div>
          <div class="item__arrow">
            <arrow-svg />
          </div>
        </div>
      </div>
      <div
        v-if="showMenuItems"
        class="font-small menu__dropdown-button"
        @click="showForm"
      >
        {{ menu.pushToLegasy }}
      </div>
      <div v-else class="font-small menu__dropdown-button" @click="showForm">
        {{ menu.pushToHome }}
      </div>
    </div>
  </section>
</template>

<script>
import menuMixin from '@/mixins/menuMixin'
import menuMobileMixin from '@/mixins/menuMobileMixin'
export default {
  name: 'FixedMenuMobile',
  mixins: [menuMixin, menuMobileMixin],
  props: {
    showMenuItems: {
      type: Boolean,
      default: true,
    },
  },
}
</script>

<style lang="sass" scoped>
@import "./mobile-styles"
.menu
  position: absolute
</style>
