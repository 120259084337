<template>
  <section class="collector">
    <div class="left-line-max" />
    <div class="left-line-middle" />
    <div class="left-line-min" />
    <div class="right-line-max" />
    <div class="right-line-middle" />
    <div class="right-line-min" />
    <div class="collector__form">
      <div class="font-subtitle collector__form-title">{{ content.title }}</div>
      <div class="font-small collector__form-description">
        {{ content.description }}
      </div>
      <lead-form v-on="$listeners" />
    </div>
  </section>
</template>

<script>
import { formBlock } from '@/content/data'
import LeadForm from '@/components/common/Form/LeadForm'

export default {
  name: 'QueueForm',
  components: { LeadForm },
  data() {
    return {
      content: formBlock,
    }
  },
}
</script>

<style lang="sass" scoped>
.collector
  padding: 6.2rem $desktopHorizontalPaddings
  background: $textPrimary
  position: relative
  overflow: hidden
  @media (max-width: 1279px)
    padding: 5rem 5.2rem 5rem $mobileHorizontalPaddings
  &__form
    &-title
      margin-bottom: 1.6rem
      color: $textWhite
    &-description
      margin-bottom: 4rem
      color: $textLightWhite

::v-deep .form-callback
  display: flex
  flex-wrap: wrap
  &__input
    position: relative
    z-index: 5
    width: 100%
    margin-bottom: 2rem
    @media (min-width: 1280px)
      width: 12.969vw
      margin-right: 22px

  &__error
    z-index: 5
    width: 100%
    margin: 0 0 2rem 0
    @media (min-width: 1280px)
      width: 12.969vw
      margin: 0 22px 2rem 0

  &__button
    position: relative
    z-index: 5
    width: 100%
    height: 5rem
    background: $buttonPrimary
    color: $textWhite
    transition: background .3s ease
    @media (min-width: 1280px)
      width: 11.615vw
    &:hover
      background: $buttonHover
    &:active
      background: $buttonClicked

    &.disabled
      background: $buttonDisabled
      cursor: not-allowed
      color: #B3B8C0
      &:hover
        background: $buttonDisabled
      &:active
        background: $buttonDisabled

  &__checkbox
    margin-bottom: 3.8rem
    @media (min-width: 1280px)
      order: 5
      margin-bottom: 0

::v-deep .input
  background: $textWhite
  &__content-input
    background: $textWhite

// украшательные линии
.left-line-min, .left-line-middle, .left-line-max,
.right-line-min, .right-line-middle, .right-line-max
  position: absolute
  background: transparent
  border: 1px solid $buttonPrimary

.left-line-min
  right: -64.27vw // -241px
  top: -25.87vw // -97px
  width: 66.13vw // 248px
  height: 55.47vw // 208px
  border-radius: 14.67vw // 55px
  @media (min-width: 768px)
    left: -25.99vw // -499px
    top: -3.91vw // -75px
    width: 33.39vw //641px
    height: 10.42vw // 200px
    border-radius: 4.427vw // 85px
.left-line-middle
  right: -75.47vw // -283px
  top: -35.2vw // -132px
  width: 84.53vw // 317px
  height: 74.93vw // 281px
  border-radius: 21.33vw // 80px
  @media (min-width: 768px)
    left: -30.677vw // -589px
    top: -8.542vw // -164px
    width: 42.66vw // 819px
    height: 19.69vw // 378px
    border-radius: 7.76vw // 149px
.left-line-max
  right: -86.93vw // -326px
  top: -44.8vw // -168px
  width: 103.2vw // 387px
  height: 93.6vw // 351px
  border-radius: 27.73vw // 104px
  @media (min-width: 768px)
    left: -35.31vw // -678px
    top: -13.23vw // -254px
    width: 51.98vw // 998px
    height: 29.01vw // 557px
    border-radius: 12.55vw // 241px

.right-line-min
  right: -33.33vw // -125px
  bottom: -43.73vw //-164px
  width: 66.13vw // 248px
  height: 55.47vw // 208px
  border-radius: 14.67vw // 55px
  @media (min-width: 1280px)
    right: -5.05vw // -97px
    bottom: -3.96vw // -76px
    width: 37.5vw //720px
    height: 10.37vw // 199px
    border-radius: 4.43vw // 85px
.right-line-middle
  right: -42.13vw // -158px
  bottom: -53.33vw // -200px
  width: 84.53vw // 317px
  height: 74.93vw // 281px
  border-radius: 21.33vw // 80px
  @media (min-width: 1280px)
    right: -10.31vw // -198px
    bottom: -8.65vw // -166px
    width: 48.02vw // 922px
    height: 19.69vw // 379px
    border-radius: 7.76vw // 149px
.right-line-max
  right: -51.73vw // -194px
  bottom: -62.93vw // -236px
  width: 103.2vw // 387px
  height: 93.6vw // 351px
  border-radius: 27.73vw // 104px
  @media (min-width: 1280px)
    right: -15.52vw // -298px
    bottom: -13.28vw // -255px
    width: 58.44vw // 1122px
    height: 29.01vw // 557px
    border-radius: 12.55vw // 241px
</style>
