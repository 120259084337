<template>
  <section ref="anchor" class="decision">
    <div class="decision__content">
      <div
        v-for="(tile, index) in content.decisions"
        :key="index"
        class="decision__content-item"
      >
        <div class="decision__content-block mod-gray">
          <img :src="`/img/vector.png`" class="decision__content-image" />
          <div class="font-medium block__description">{{ tile.text }}</div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { decisionBlockFirst } from '@/content/data'
import offsetMixin from '@/mixins/offsetMixin'

export default {
  name: 'QueueDecisionFirst',
  mixins: [offsetMixin],
  data() {
    return {
      content: decisionBlockFirst,
    }
  },
}
</script>

<style lang="sass" scoped>
.decision
  padding: 6rem $desktopHorizontalPaddings 0
  @media (max-width: 1279px)
    padding: 4rem $mobileHorizontalPaddings 0
  &__content
    display: flex
    flex-wrap: wrap
    width: 100%
    gap: 2rem
    .decision__content-item
      width: 31%
      height: 15rem
    @media (max-width: 1024px)
      .decision__content-item
        width: 100%
        height: 6rem

    &-image
      width: 2rem
      height: 2rem
      object-fit: cover
      object-position: center

    &-block
      display: flex
      gap: 20px
      align-items: center
      justify-content: space-between
      height: 100%
      width: 100%
      padding: 3.2rem 2.4rem
      border-radius: 20px
      border: 1px solid $backgroundGray
      @media (min-width: 1440px) and (max-width: 1599px)
        padding: 2.2rem 1.4rem
      @media (min-width: 1280px) and (max-width: 1439px)
        padding: 2.2rem 1.4rem
      @media (max-width: 1279px)
        padding: 2rem 1.4rem
      @media (max-width: 1024px)
        justify-content: flex-start
      .block__description
        font-weight: 500
        font-size: 2rem
        line-height: 2.4rem
        @media (min-width: 1280px) and (max-width: 1439px)
          font-size: 1.6rem
        @media (max-width: 1279px)
          font-size: 1.4rem
          line-height: 1.6rem
      &.mod-gray
        background: $backgroundGray
</style>
