<template>
  <table>
    <tr>
      <th style="text-align: center" colspan="2">
        <div class="font-medium big_row_table">
          Услуга Г.Новороссийск
        </div>
      </th>
    </tr>
    <tr>
      <td style="width: 50%">
        <div class="font-medium">
          Право доступа к ПО
        </div>
      </td>
      <td style="width: 50%">
        <div class="font-medium">
          1200 рублей
        </div>
      </td>
    </tr>
    <tr>
      <td style="width: 50%">
        <div class="font-medium">
          Право доступа к ПО + аккредитация
        </div>
      </td>
      <td style="width: 50%">
        <div class="font-medium">
          1400 рублей
        </div>
      </td>
    </tr>
    <tr>
      <td style="width: 50%">
        <div class="font-medium">
          Исправление в электронной ТН
        </div>
      </td>
      <td style="width: 50%">
        <div class="font-medium">
          120 рублей
        </div>
      </td>
    </tr>
    <tr>
      <th style="text-align: center" colspan="2">
        <div class="font-medium">
          В адрес остальных стивидорных площадок
        </div>
      </th>
    </tr>
    <tr>
      <td style="width: 50%">
        <div class="font-medium">
          Право доступа к ПО
        </div>
      </td>
      <td style="width: 50%">
        <div class="font-medium">
          200 рублей
        </div>
      </td>
    </tr>
  </table>
</template>

<script>
import offsetMixin from '@/mixins/offsetMixin'

export default {
  name: 'TableInformation',
  mixins: [offsetMixin],
}
</script>

<style lang="sass" scoped>
table
  width: 100%
  border-collapse: collapse
  margin-bottom: 2rem

  th, td
    border: 1px solid black
    padding: 8px
    text-align: left
</style>
