import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export const store = new Vuex.Store({
  state: {
    blockOffset: {
      HeaderComponent: 0,
      QueueDecision: 0,
      QueueDescription: 0,
      QueueTerms: 0,
      QueueCase: 0,
      QueuePartners: 0,
      FooterComponent: 0,
    },
  },
  getters: {
    getOffsets(state) {
      return state.blockOffset
    },
  },
  mutations: {
    SET_OFFSET(state, offset) {
      state.blockOffset[offset.block] = offset.value
    },
  },
  actions: {
    setOffset({ commit }, offset) {
      commit('SET_OFFSET', offset)
    },
  },
})
