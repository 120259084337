<template>
  <section ref="anchor" class="terms">
    <div v-html-safe="content.title" class="font-title" />
    <div class="terms__content">
      <div
        v-for="(tile, index) in content.tiles"
        :key="index"
        class="terms__content-tile"
      >
        <div class="tile__title font-subtitle">{{ tile.subtitle }}</div>
        <div
          v-for="(task, index2) in tile.tasks"
          :key="index2"
          class="tile__task"
        >
          <div class="tile__task-list" />
          <div class="font-medium mod-light">{{ task }}</div>
        </div>
      </div>
    </div>
    <div v-html-safe="content.button" class="terms__button font-medium" />
  </section>
</template>

<script>
import { termsBlock } from '@/content/data'
import offsetMixin from '@/mixins/offsetMixin'

export default {
  name: 'QueueTerms',
  mixins: [offsetMixin],
  data() {
    return {
      content: termsBlock,
    }
  },
}
</script>

<style lang="sass" scoped>
.terms
  padding: $desktopVerticalPaddings $desktopHorizontalPaddings
  @media (max-width: 1279px)
    padding: $mobileVerticalPaddings $mobileHorizontalPaddings
  &__content
    display: flex
    justify-content: space-between
    margin-top: 6rem
    margin-bottom: 2.5rem
    @media (max-width: 1279px)
      flex-direction: column
      justify-content: flex-start
      margin-top: 2.4rem
      margin-bottom: 0rem
    &-tile
      width: calc(50% - 1rem)
      padding: 4.8rem 4.8rem 3.6rem
      border-radius: 2rem
      @media (max-width: 1279px)
        width: 100%
        padding: 3rem 3rem 2.4rem
        margin-bottom: 1.6rem
        border-radius: 1.6rem
      .tile__title
        margin-bottom: 3.2rem
        @media (max-width: 1279px)
          margin-bottom: 1.8rem
      .tile__task
        display: flex
        margin-bottom: 2.4rem
        @media (max-width: 1279px)
          margin-bottom: 1.6rem
        &-list
          flex-shrink: 0
          width: 1.5rem
          height: 1.5rem
          margin-top: .3rem
          margin-right: 1.2rem
          border: 2px solid $textPrimary
          border-radius: 50%
          @media (max-width: 1279px)
            width: 1.2rem
            height: 1.2em
            margin-top: .2rem
            margin-right: .8rem
      &:first-of-type
        background: $backgroundGray
      &:last-of-type
        background: $textPrimary
        .tile__title
          color: $textWhite
        .tile__task-list
          border: 2px solid $buttonPrimary
        .mod-light
          color: $textLightWhite
  &__button
    display: flex
    justify-content: center
    align-items: center
    padding: 2.4rem
    border: 1px solid $backgroundGray
    border-radius: 27px
    @media (max-width: 1279px)
      padding: 1.6rem
    ::v-deep span
      font-size: 2.4rem
      color: $buttonPrimary
</style>
