import { render, staticRenderFns } from "./QueueForm.vue?vue&type=template&id=18c80f29&scoped=true"
import script from "./QueueForm.vue?vue&type=script&lang=js"
export * from "./QueueForm.vue?vue&type=script&lang=js"
import style0 from "./QueueForm.vue?vue&type=style&index=0&id=18c80f29&prod&lang=sass&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "18c80f29",
  null
  
)

export default component.exports