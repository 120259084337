<template>
  <div>
    <fixed-menu-desktop
      :show-menu-items="false"
      @show-form="redirectToHomePage"
    />
    <float-menu-desktop
      :show-menu-items="false"
      @show-form="redirectToHomePage"
    />
    <fixed-menu-mobile
      :show-menu-items="false"
      @show-form="redirectToHomePage"
    />
    <float-menu-mobile
      :show-menu-items="false"
      @show-form="redirectToHomePage"
    />
    <header-component
      @show-form-presentation="showFormPresentation"
      @show-form-demonstration="showFormDemonstration"
    />
    <legal-information />
    <footer-component
      :visible="modalRequestOpened"
      :result="requestResult"
      @close="modalRequestOpened = false"
      @requested="showRequestModal"
    />
    <modal-request-result
      :visible="modalRequestOpened"
      :result="requestResult"
      @close="modalRequestOpened = false"
    />
    <modal-form
      :visible="modalFormOpened"
      @close="modalFormOpened = false"
      @requested="showRequestModal"
    />
    <modal-form-demonstration
      :is-demonstration="true"
      :visible="modalFormDemonstrationOpened"
      @close="modalFormDemonstrationOpened = false"
      @requested="showRequestDemonstrationModal"
    />
    <modal-form-presentation
      :is-presentation="true"
      :visible="modalFormPresentationOpened"
      @close="modalFormPresentationOpened = false"
      @requested="showRequestPresentationModal"
    />
  </div>
</template>

<script>
import FixedMenuDesktop from '@/components/menu/FixedMenuDesktop'
import FloatMenuDesktop from '@/components/menu/FloatMenuDesktop'
import FixedMenuMobile from '@/components/menu/FixedMenuMobile'
import FloatMenuMobile from '@/components/menu/FloatMenuMobile'
import FooterComponent from '@/components/Footer.vue'
import HeaderComponent from '@/components/Header.vue'
import ModalForm from '@/components/common/Modals/ModalForm.vue'
import ModalRequestResult from '@/components/common/Modals/ModalRequestResult.vue'
import LegalInformation from '@/components/LegalInformation.vue'
import ModalFormDemonstration from '@/components/common/Modals/ModalFormDemonstration.vue'
import ModalFormPresentation from '@/components/common/Modals/ModalFormPresentation.vue'

export default {
  name: 'LandingPage',
  components: {
    ModalFormPresentation,
    ModalFormDemonstration,
    LegalInformation,
    ModalRequestResult,
    ModalForm,
    FixedMenuDesktop,
    FloatMenuDesktop,
    FixedMenuMobile,
    FloatMenuMobile,
    HeaderComponent,
    FooterComponent,
  },

  data() {
    return {
      modalRequestOpened: false,
      modalFormOpened: false,
      modalFormPresentationOpened: false,
      modalFormDemonstrationOpened: false,
      requestResult: '',
    }
  },
  methods: {
    // открытие модальной формы заявки
    showForm() {
      this.modalFormOpened = true
    },
    showFormPresentation() {
      this.modalFormPresentationOpened = true
    },
    showFormDemonstration() {
      this.modalFormDemonstrationOpened = true
    },
    // вывод сообщения о результате отправки
    showRequestModal(result) {
      this.modalFormOpened = false
      this.requestResult = result
      this.modalRequestOpened = true
    },

    showRequestPresentationModal(result) {
      this.modalFormPresentationOpened = false
      this.requestResult = result
      this.modalRequestOpened = true
    },

    showRequestDemonstrationModal(result) {
      this.modalFormDemonstrationOpened = false
      this.requestResult = result
      this.modalRequestOpened = true
    },

    redirectToHomePage() {
      const queryParams = window.location.search

      this.$router.push(`/${queryParams}`)
    },
  },
}
</script>

<style lang="sass" scoped>
::v-deep .v-modal
  background: rgba(25, 31, 38, 0.7)
</style>
