var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{ref:"anchor",staticClass:"information"},[_c('div',{staticClass:"information__content"},[_c('div',{directives:[{name:"html-safe",rawName:"v-html-safe",value:(_vm.content.generalInformation.title),expression:"content.generalInformation.title"}],staticClass:"font-subtitle information__title"}),_c('div',{directives:[{name:"html-safe",rawName:"v-html-safe",value:(
        _vm.content.generalInformation.description.replace(/\n/g, '<br>')
      ),expression:"\n        content.generalInformation.description.replace(/\\n/g, '<br>')\n      "}],staticClass:"font-medium"})]),_c('div',{staticClass:"information__content"},[_c('div',{directives:[{name:"html-safe",rawName:"v-html-safe",value:(_vm.content.functionalCharacteristics.title),expression:"content.functionalCharacteristics.title"}],staticClass:"font-subtitle information__title"}),_c('div',{directives:[{name:"html-safe",rawName:"v-html-safe",value:(
        _vm.content.functionalCharacteristics.description.replace(/\n/g, '<br>')
      ),expression:"\n        content.functionalCharacteristics.description.replace(/\\n/g, '<br>')\n      "}],staticClass:"font-medium"})]),_c('div',{staticClass:"information__content"},[_c('div',{directives:[{name:"html-safe",rawName:"v-html-safe",value:(_vm.content.developmentDirections.title),expression:"content.developmentDirections.title"}],staticClass:"font-subtitle information__title"}),_c('div',{directives:[{name:"html-safe",rawName:"v-html-safe",value:(_vm.content.developmentDirections.description.replace(/\n/g, '<br>')),expression:"content.developmentDirections.description.replace(/\\n/g, '<br>')"}],staticClass:"font-medium"})]),_c('div',{staticClass:"information__content"},[_c('div',{directives:[{name:"html-safe",rawName:"v-html-safe",value:(_vm.content.contractOffer.title),expression:"content.contractOffer.title"}],staticClass:"font-subtitle information__title"}),_c('div',{directives:[{name:"html-safe",rawName:"v-html-safe",value:(
        _vm.content.contractOffer.descriptionTop.replace(/\n/g, '<br>')
      ),expression:"\n        content.contractOffer.descriptionTop.replace(/\\n/g, '<br>')\n      "}],staticClass:"font-medium information__title"}),_c('TableInformation'),_c('div',{directives:[{name:"html-safe",rawName:"v-html-safe",value:(
        _vm.content.contractOffer.descriptionBottom.replace(/\n/g, '<br>')
      ),expression:"\n        content.contractOffer.descriptionBottom.replace(/\\n/g, '<br>')\n      "}],staticClass:"font-medium"})],1),_c('div',{staticClass:"information__content"},[_c('div',{directives:[{name:"html-safe",rawName:"v-html-safe",value:(_vm.content.documents.title),expression:"content.documents.title"}],staticClass:"font-subtitle information__title"}),_c('div',{directives:[{name:"html-safe",rawName:"v-html-safe",value:(_vm.content.documents.description.replace(/\n/g, '<br>')),expression:"content.documents.description.replace(/\\n/g, '<br>')"}],staticClass:"font-medium"})])])
}
var staticRenderFns = []

export { render, staticRenderFns }