import Vue from 'vue'
import Router from 'vue-router'

import LandingPage from '@/views/LandingPage.vue'
import LegacyPage from '@/views/LegacyPage.vue'
import PageNotFound from '@/views/PageNotFound.vue'

const originalPush = Router.prototype.push

Router.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}
Vue.use(Router)

export const router = new Router({
  mode: 'history',
  routes: [
    {
      path: '/',
      name: 'LandingPage',
      component: LandingPage,
    },
    {
      path: '/legal',
      name: 'legal',
      component: LegacyPage,
    },
    {
      path: '*',
      name: 'Page404',
      component: PageNotFound,
    },
  ],
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    }

    return { x: 0, y: 0 }
  },
})

export default router
