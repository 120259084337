<template>
  <section :class="['menu-desktop', { hidden: !isVisible }]">
    <div class="menu__logo">
      <div class="menu__logo-picture" @click="goToSection('HeaderComponent')">
        <svg
          width="179"
          height="30"
          viewBox="0 0 260 40"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M51.7214 8.68652C54.2529 8.68652 56.2659 9.35815 57.7299 10.7014C59.1939 12.0447 59.9259 13.8764 59.9259 16.1966V16.5934C59.9259 18.9442 59.1939 20.8064 57.7299 22.1497C56.2659 23.4929 54.2529 24.1646 51.7214 24.1646H42.9984V32.3157H38.0879V8.68652H51.7214ZM54.0394 18.6999C54.6189 18.242 54.8934 17.5398 54.8934 16.624V16.2271C54.8934 15.3418 54.6189 14.6701 54.0394 14.1817C53.4599 13.7238 52.6364 13.4795 51.5689 13.4795H42.9679V19.3716H51.5689C52.6669 19.4021 53.4599 19.1579 54.0394 18.6999Z"
            fill="#212832"
          />
          <path
            d="M65.9344 31.6441C64.4399 30.9114 63.2504 29.8429 62.4574 28.4691C61.6339 27.0953 61.2374 25.4773 61.2374 23.5845V23.1266C61.2374 21.2033 61.6339 19.5547 62.4269 18.1504C63.2199 16.7461 64.3789 15.6776 65.8734 14.9449C67.3679 14.2122 69.1674 13.8153 71.2414 13.8153C73.2849 13.8153 75.0539 14.1817 76.5484 14.9449C78.0429 15.6776 79.1714 16.7461 79.9644 18.1504C80.7574 19.5547 81.1539 21.2033 81.1539 23.1266V23.5845C81.1539 25.4468 80.7574 27.0953 79.9644 28.4691C79.1714 29.8429 78.0124 30.9114 76.5484 31.6441C75.0539 32.3768 73.2849 32.7431 71.2414 32.7431C69.2284 32.7431 67.4594 32.3768 65.9344 31.6441ZM74.9624 26.8511C75.8469 26.0879 76.3044 24.9888 76.3044 23.5845V23.1876C76.3044 21.7223 75.8774 20.5927 74.9929 19.799C74.1084 19.0052 72.8579 18.6083 71.2414 18.6083C69.5944 18.6083 68.3439 19.0052 67.4594 19.7684C66.5749 20.5622 66.1479 21.6917 66.1479 23.1571V23.554C66.1479 24.9583 66.6054 26.0573 67.4899 26.8205C68.3744 27.5838 69.6249 27.9806 71.2414 27.9806C72.8579 27.9806 74.0779 27.6143 74.9624 26.8511Z"
            fill="#212832"
          />
          <path
            d="M96.2209 14.2733V18.8831H92.5609C90.5174 18.8831 89.0534 19.4326 88.2299 20.5622V32.2852H83.5024V14.2733H87.9249L88.0469 15.9218C89.4194 14.8228 91.1274 14.2733 93.1709 14.2733H96.2209Z"
            fill="#212832"
          />
          <path
            d="M108.97 18.8831V25.7215C108.97 26.3626 109.153 26.8816 109.488 27.2174C109.824 27.5532 110.312 27.7364 110.952 27.7364H114.826V32.3157H110.342C108.451 32.3157 106.987 31.7662 105.889 30.6366C104.791 29.5071 104.242 28.0112 104.242 26.0879V18.8831H98.7524V14.2733H103.144C103.602 14.2733 103.907 14.1817 104.09 13.9985C104.273 13.8153 104.364 13.449 104.364 12.9605V9.48027H108.97V14.2733H115.802V18.8831H108.97Z"
            fill="#212832"
          />
          <path
            d="M117.876 13.5406V8.68652H139.622V13.5406H131.235V32.3157H126.263V13.5406H117.876Z"
            fill="#212832"
          />
          <path
            d="M154.384 14.2733V18.8831H150.724C148.681 18.8831 147.217 19.4326 146.393 20.5622V32.2852H141.666V14.2733H146.088L146.21 15.9218C147.583 14.8228 149.291 14.2733 151.334 14.2733H154.384Z"
            fill="#212832"
          />
          <path
            d="M172.318 15.9523C173.965 17.3567 174.789 19.341 174.789 21.8749V32.3157H170.366L170.214 30.6366C168.628 32.0409 166.371 32.7736 163.473 32.7736C161.094 32.7736 159.234 32.2852 157.953 31.3388C156.641 30.3924 156.001 29.0491 156.001 27.2785C156.001 24.3172 157.892 22.5771 161.643 22.1191L169.97 21.1117C169.787 20.2569 169.299 19.6158 168.506 19.1579C167.713 18.6999 166.645 18.4862 165.273 18.4862C163.931 18.4862 162.894 18.6999 162.131 19.1273C161.369 19.5547 160.972 20.1348 160.972 20.8675V20.9896H156.123V20.8675C156.123 19.4631 156.519 18.2115 157.282 17.1735C158.044 16.105 159.112 15.3112 160.515 14.7312C161.918 14.1512 163.534 13.8764 165.395 13.8764C168.353 13.8459 170.671 14.548 172.318 15.9523ZM167.591 27.8585C168.719 27.3701 169.543 26.729 170.031 25.8742V24.7446L162.863 25.5994C162.192 25.6605 161.674 25.8436 161.308 26.1184C160.911 26.3931 160.728 26.729 160.728 27.1258C160.728 28.0722 161.704 28.5607 163.687 28.5607C165.151 28.5607 166.462 28.3165 167.591 27.8585Z"
            fill="#212832"
          />
          <path
            d="M194.614 16.2576C196.078 17.8757 196.81 20.2569 196.81 23.4319V32.3157H192.052V23.4319C192.052 21.8749 191.655 20.6843 190.893 19.86C190.13 19.0357 188.971 18.6083 187.507 18.6083C186.47 18.6083 185.555 18.8526 184.732 19.3716C183.908 19.8905 183.268 20.5927 182.841 21.478V32.3157H178.083V14.2733H182.475L182.597 16.0134C184.03 14.6091 185.891 13.9069 188.148 13.8459C190.984 13.8459 193.15 14.6396 194.614 16.2576Z"
            fill="#212832"
          />
          <path
            d="M217.214 18.6999L206.509 27.8891H217.214V32.3157H200.073V27.8891L210.748 18.7305H200.073V14.2733H217.214V18.6999Z"
            fill="#212832"
          />
          <path
            d="M220.569 11.312C220.02 10.7625 219.746 9.99926 219.746 9.0834C219.746 8.16754 220.02 7.43485 220.569 6.8548C221.118 6.30529 221.82 6 222.704 6C223.589 6 224.321 6.27476 224.839 6.8548C225.388 7.40432 225.632 8.16754 225.632 9.0834C225.632 9.99926 225.358 10.7319 224.809 11.312C224.26 11.8615 223.558 12.1668 222.674 12.1668C221.82 12.1363 221.118 11.8615 220.569 11.312ZM225.083 32.3157H220.356V14.2733H225.083V32.3157Z"
            fill="#212832"
          />
          <path
            d="M238.198 18.8831V25.7215C238.198 26.3626 238.381 26.8816 238.717 27.2174C239.052 27.5532 239.54 27.7364 240.181 27.7364H244.054V32.3157H239.571C237.68 32.3157 236.216 31.7662 235.118 30.6366C234.02 29.5071 233.471 28.0112 233.471 26.0879V18.8831H227.981V14.2733H232.373C232.83 14.2733 233.135 14.1817 233.318 13.9985C233.501 13.8153 233.593 13.449 233.593 12.9605V9.48027H238.229V14.2733H245V18.8831H238.198Z"
            fill="#212832"
          />
          <path
            d="M6.978 8.74805V12.8084C6.978 13.8158 6.2155 14.6401 5.2395 14.7317C5.1785 14.7317 1 14.7317 1 14.7317V32.2856H18.812V28.2253C18.812 27.2179 19.5745 26.3936 20.5505 26.302C20.6115 26.302 24.79 26.302 24.79 26.302V8.74805H6.978ZM16.616 15.0675C17.6225 15.0675 18.446 15.8918 18.446 16.8992V24.1345C18.446 25.1419 17.6225 25.9662 16.616 25.9662H9.1435C8.137 25.9662 7.3135 25.1419 7.3135 24.1345V16.8992C7.3135 15.8918 8.137 15.0675 9.1435 15.0675H16.616Z"
            fill="#4CC0CC"
          />
        </svg>
      </div>
    </div>
    <div v-if="showMenuItems" class="menu__items">
      <div
        v-for="(item, index) in menu.menuItems"
        :key="index"
        :class="[
          'font-small',
          'menu__items-item',
          { active: item.component === visibleElement },
        ]"
        @click="goToSection(item.component)"
      >
        {{ item.label }}
      </div>
    </div>
    <div class="menu__buttons">
      <div
        v-if="showMenuItems"
        class="font-small menu__buttons-item"
        @click="$emit('show-form')"
      >
        {{ menu.pushToLegasy }}
      </div>
      <div
        v-else
        class="font-small menu__buttons-item"
        @click="$emit('show-form')"
      >
        {{ menu.pushToHome }}
      </div>
    </div>
  </section>
</template>

<script>
import menuMixin from '@/mixins/menuMixin'
import floatMenuMixin from '@/mixins/floatMenuMixin'
export default {
  name: 'FloatMenuDesktop',
  mixins: [menuMixin, floatMenuMixin],
  props: {
    showMenuItems: {
      type: Boolean,
      default: true,
    },
  },
}
</script>

<style lang="sass" scoped>
@import "./desktop-styles"
.menu-desktop
  z-index: 500
  position: fixed
  height: 8rem
  background: $textWhite
  border-bottom: 1px solid $backgroundGray
  transition: transform .5s ease
  &.hidden
    transform: translateY(-8rem)
  .menu__items-item
    color: $textSecondary
    &.active
      color: $buttonPrimary
      border-bottom: 1px solid $buttonPrimary
  .menu__logo-picture
    cursor: pointer
</style>
