<template>
  <section ref="anchor" class="description">
    <div class="font-title description__title">{{ content.title }}</div>
    <div class="swiper-control">
      <div slot="button-prev" class="swiper-button-prev">
        <svg class="button__svg">
          <use xlink:href="#left-angle" />
        </svg>
      </div>
      <div slot="button-next" class="swiper-button-next">
        <svg class="button__svg">
          <use xlink:href="#right-angle" />
        </svg>
      </div>
    </div>
    <swiper :options="swiperOptions" class="description__swiper">
      <swiper-slide v-for="(card, index) in content.cards" :key="index">
        <div class="description__card">
          <div class="description__card-icon">
            <img :src="`/img/description/${card.icon}.svg`" alt="icon" />
          </div>
          <div>
            <div class="description__card-title">{{ card.title }}</div>
            <div class="description__card-content font-small">
              {{ card.content }}
            </div>
          </div>
        </div>
      </swiper-slide>
    </swiper>
    <div class="description__sprite">
      <svg xmlns="http://www.w3.org/2000/svg">
        <symbol id="left-angle" viewBox="0 0 24 24" fill="none">
          <path
            d="M16 20L8 12L16 4"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </symbol>
        <symbol id="right-angle" viewBox="0 0 24 24" fill="none">
          <g clip-path="url(#clip0_3231_1373)">
            <path
              d="M8 20L16 12L8 4"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </g>
        </symbol>
      </svg>
    </div>
  </section>
</template>

<script>
import { descriptionBlock } from '@/content/data'
import offsetMixin from '@/mixins/offsetMixin'

// Import Swiper Vue.js components
import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
// Import Swiper styles
import 'swiper/css/swiper.css'

export default {
  name: 'QueueDescription',
  components: {
    Swiper,
    SwiperSlide,
  },
  mixins: [offsetMixin],
  data() {
    return {
      content: descriptionBlock,

      swiperOptions: {
        spaceBetween: 20,
        slidesOffsetAfter: 50,
        navigation: {
          prevEl: '.swiper-button-prev',
          nextEl: '.swiper-button-next',
        },
        breakpoints: {
          320: {
            slidesPerView: 1.7,
            spaceBetween: 20,
          },
          640: {
            slidesPerView: 2.3,
            spaceBetween: 20,
          },
          780: {
            slidesPerView: 3.5,
            spaceBetween: 20,
          },
          1020: {
            slidesPerView: 5.2,
            spaceBetween: 20,
          },
          1280: {
            slidesPerView: 4.2,
            spaceBetween: 20,
          },
          1920: {
            slidesPerView: 5.2,
            spaceBetween: 20,
          },
        },
      },
    }
  },
}
</script>

<style lang="sass" scoped>
.description
  position: relative
  padding: $desktopVerticalPaddings 0 $desktopVerticalPaddings $desktopHorizontalPaddings
  @media (max-width: 1279px)
    padding: $mobileVerticalPaddings 0 $mobileVerticalPaddings $mobileHorizontalPaddings
  &__title
    width: 50%
    @media (min-width: 1440px) and (max-width: 1599px)
      width: 60%
    @media (min-width: 1280px) and (max-width: 1439px)
      width: 70%
    @media (max-width: 1279px)
      width: 60%
  .swiper-control
    position: absolute
    display: flex
    top: 17.3rem
    right: $desktopHorizontalPaddings
    @media (max-width: 1279px)
      top: 6.3rem
      right: 2rem
    z-index: 10
    .swiper-button-next, .swiper-button-prev
      position: relative
      display: flex
      justify-content: center
      align-items: center
      width: 4.4rem
      height: 4.4rem
      left: auto
      right: auto
      margin-top: 0
      margin-left: 1.4rem
      background: $textWhite
      border: 1px solid $backgroundGray
      border-radius: 1.2rem
      @media (max-width: 1279px)
        width: 3.4rem
        height: 3.4rem
      &::after
        display: none
      .button__svg
        width: 2.4rem
        height: 2.4rem
        stroke: #212832
        @media (max-width: 1279px)
          width: 1.8rem
          height: 1.8rem

    .swiper-button-disabled
      opacity: 1
      background: $buttonDisabled
      border: none
      .button__svg
        stroke: #A7ADB6

  &__swiper
    margin-top: 6rem
    @media (max-width: 1279px)
      margin-top: 2.4rem
  &__card
    display: flex
    flex-direction: column
    justify-content: space-between
    height: 30.5rem
    padding: 2.4rem 2.4rem 3rem
    background: #D9E1E3
    border-radius: 20px
    @media (max-width: 1279px)
      height: 21.0rem
      padding: 1.4rem 1.4rem 1.6rem
      border-radius: 16px
    &-icon
      width: 6rem
      height: 6rem
      @media (max-width: 1279px)
        width: 3.9rem
        height: 3.9rem
      img
        width: 100%
        height: 100%
    &-title
      font-family: $primaryFont
      font-weight: 500
      font-size: 2.2rem
      line-height: 2.4rem
      color: $textPrimary
      @media (max-width: 1279px)
        font-size: 1.8rem
    &-content
      min-height: 5.5rem
      margin-top: .8rem

  &__sprite
    display: none
</style>
