import { render, staticRenderFns } from "./QueueIndicators.vue?vue&type=template&id=b44f3eb0&scoped=true"
import script from "./QueueIndicators.vue?vue&type=script&lang=js"
export * from "./QueueIndicators.vue?vue&type=script&lang=js"
import style0 from "./QueueIndicators.vue?vue&type=style&index=0&id=b44f3eb0&prod&lang=sass&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b44f3eb0",
  null
  
)

export default component.exports