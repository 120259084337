import { render, staticRenderFns } from "./QueueDecisionFirst.vue?vue&type=template&id=54256fcb&scoped=true"
import script from "./QueueDecisionFirst.vue?vue&type=script&lang=js"
export * from "./QueueDecisionFirst.vue?vue&type=script&lang=js"
import style0 from "./QueueDecisionFirst.vue?vue&type=style&index=0&id=54256fcb&prod&lang=sass&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "54256fcb",
  null
  
)

export default component.exports