<template>
  <modal-wrapper :visible="visible" v-on="$listeners">
    <template v-if="result.length" slot="content">
      <div class="font-subtitle">{{ content[result].title }}</div>
      <div class="request__description font-small">
        {{ content[result].description }}
      </div>
      <div class="request__button" @click="$emit('close')">
        {{ content.button }}
      </div>
    </template>
  </modal-wrapper>
</template>

<script>
import { modalRequestBlock } from '@/content/data'
import ModalWrapper from '@/components/common/Modals/ModalWrapper'

export default {
  name: 'ModalRequestResult',
  components: { ModalWrapper },
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    result: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      content: modalRequestBlock,
    }
  },
}
</script>

<style lang="sass" scoped>
.request__description
  margin-top: .8rem
  margin-bottom: 3.2rem
.request__button
  display: flex
  justify-content: center
  align-items: center
  height: 5rem
  border-radius: 50px
  background: $buttonPrimary
  color: $textWhite
  cursor: pointer
  font-family: $primaryFont
  font-weight: 400
  font-size: 1.6rem
  line-height: 2.2rem
  transition: background .3s ease
  &:hover
    background: $buttonHover
  &:active
    background: $buttonClicked
</style>
