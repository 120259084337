<template>
  <el-dialog
    :visible="visible"
    :close-on-press-escape="false"
    :close-on-click-modal="false"
    :show-close="false"
    :modal-append-to-body="false"
    top="5rem"
    custom-class="dialog"
  >
    <div class="dialog__close" @click="$emit('close')">
      <div class="close__icon" />
    </div>
    <div class="dialog__content">
      <slot name="content" />
    </div>
  </el-dialog>
</template>

<script>
export default {
  name: 'ModalWrapper',
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
  },
}
</script>

<style lang="sass" scoped>
::v-deep .dialog
  position: relative
  width: 500px
  border-radius: 2rem !important
  @media (max-width: 1279px)
    width: calc(100% - 4rem)

  .el-dialog__header
    display: none
  .el-dialog__body
    padding: 0

  &__close
    position: absolute
    top: 2.4rem
    right: 2.4rem
    cursor: pointer
    .close__icon
      width: 1.6rem
      height: 1.6rem
      background: url('~@/assets/img/new-close.svg')
      background-size: cover

  &__content
    padding: 6rem 4rem
    @media (max-width: 1279px)
      padding: 4rem 2.4rem
</style>
