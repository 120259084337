<template>
  <section ref="anchor" class="information">
    <div class="information__content">
      <div
        v-html-safe="content.generalInformation.title"
        class="font-subtitle information__title"
      />
      <div
        v-html-safe="
          content.generalInformation.description.replace(/\n/g, '<br>')
        "
        class="font-medium"
      />
    </div>
    <div class="information__content">
      <div
        v-html-safe="content.functionalCharacteristics.title"
        class="font-subtitle information__title"
      />
      <div
        v-html-safe="
          content.functionalCharacteristics.description.replace(/\n/g, '<br>')
        "
        class="font-medium"
      />
    </div>
    <div class="information__content">
      <div
        v-html-safe="content.developmentDirections.title"
        class="font-subtitle information__title"
      />
      <div
        v-html-safe="content.developmentDirections.description.replace(/\n/g, '<br>')"
        class="font-medium"
      />
    </div>
    <div class="information__content">
      <div
        v-html-safe="content.contractOffer.title"
        class="font-subtitle information__title"
      />
      <div
        v-html-safe="
          content.contractOffer.descriptionTop.replace(/\n/g, '<br>')
        "
        class="font-medium information__title"
      />
      <TableInformation />
      <div
        v-html-safe="
          content.contractOffer.descriptionBottom.replace(/\n/g, '<br>')
        "
        class="font-medium"
      />
    </div>
    <div class="information__content">
      <div
        v-html-safe="content.documents.title"
        class="font-subtitle information__title"
      />
      <div
        v-html-safe="content.documents.description.replace(/\n/g, '<br>')"
        class="font-medium"
      />
    </div>
  </section>
</template>

<script>
import { legalInformation } from '@/content/data'
import offsetMixin from '@/mixins/offsetMixin'
import TableInformation from '@/components/common/Table/TableInformation.vue'

export default {
  name: 'LegalInformation',
  components: { TableInformation },
  mixins: [offsetMixin],
  data() {
    return {
      content: legalInformation,
    }
  },
}
</script>

<style lang="sass">
.information
  padding: $desktopVerticalPaddings $desktopHorizontalPaddings
  @media (max-width: 1279px)
    padding: 6rem $mobileHorizontalPaddings
  &__title
    margin-bottom: 2rem

  &__text
    border-bottom: 1px solid $textBlack !important

  &__content
    display: flex
    justify-content: space-between
    flex-wrap: wrap
    margin-bottom: 6rem
</style>
