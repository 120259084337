<template>
  <section class="page404">
    <div class="page404__info">
      <div class="page404__info-logo" @click="goHome" />
      <div class="page404__info-picture" @click="goHome">
        <img src="@/assets/img/new-404-picture.png" alt="404" />
      </div>
      <div class="page404__info-title">404</div>
      <div class="page404__info-subtitle">Страница не найдена</div>
      <div class="page404__info-description">
        Возможно, запрошенная страница временно не доступна или удалена
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'PageNotFound',
  methods: {
    goHome() {
      const queryParams = window.location.search

      this.$router.push(`/${queryParams}`)
    },
  },
}
</script>

<style lang="sass" scoped>
.page404
  display: flex
  flex-direction: column
  justify-content: space-between
  align-items: center
  height: calc(100vh - 7rem)
  padding-top: 3.2rem
  &__info
    display: flex
    flex-direction: column
    align-items: center
    &-logo
      width: 18.7rem
      height: 3.3rem
      margin-bottom: 2.4rem
      background-size: cover
      cursor: pointer
      img
        width: 100%
        height: 100%
    &-picture
      width: 22rem
      height: 22rem
      margin-bottom: 2rem
      img
        width: 100%
        height: 100%
    &-title
      margin-bottom: .8rem
      font-size: 4.8rem
      font-weight: 800
      line-height: 5.6rem
      color: #181818
    &-subtitle
      margin-bottom: .8rem
      font-size: 2.4rem
      font-weight: 800
      line-height: 3.2rem
      color: #181818
    &-description
      padding: 0 4.5rem
      font-size: 1.6rem
      font-weight: 400
      line-height: 2rem
      color: #5C5C5C

  ::v-deep &__button .new-button
    width: 22rem
    margin-bottom: 4.1rem
</style>
